import { Component, Input, OnInit } from '@angular/core';

//Table Imports - Mockup Only
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

interface Group {
  group: string;
}

interface ContactsTable {
  eid: string;
  maskedNumber: string;
  group: Group[];
  smsEnabled: boolean;
  action: string;
}

@Component({
  selector: 'app-people-acc-contacts-table',
  templateUrl: './people-acc-contacts-table.component.html',
  styleUrls: ['./people-acc-contacts-table.component.scss'],
})
export class PeopleAccContactsTableComponent implements OnInit {
  editTooltip = 'Edit contact details';
  deleteTooltip = 'Delete contact';
  tableContactsData: ContactsTable[] = [];
  displayedColumns: string[] = [
    'eid',
    'maskedNumber',
    'tag',
    'group',
    'smsEnabled',
    'action',
  ];
  dataSource = new MatTableDataSource<ContactsTable>();
  paginator: MatPaginator;
  sort: MatSort;

  constructor(private _liveAnnouncer: LiveAnnouncer) {}

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @Input() openContactForm: (type: string, data: any) => void;
  @Input() deleteRecord: (type: string, data: any) => void;
  @Input() contactsData: any[];
  @Input() isLoading: boolean;

  ngOnInit(): void {}

  ngOnChanges() {
    this.dataSource.data = this.contactsData ?? [];
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openEditContact(type, data) {
    this.openContactForm(type, data);
  }

  onDeleteContact(type, data) {
    this.deleteRecord(type, data);
  }

  sortData(sortState: Sort) {
    console.log("SORT", sortState);
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
