import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChangeLogService } from 'src/app/service/change-log.service';
import { RegistrationDeviceService } from 'src/app/service/device-registration.service';
import { ProjectService } from 'src/app/service/project.service';
import { SnackBarService } from 'src/app/service/snack-bar.service';
import { UnregisterPromptComponent } from '../../forms/unregister-prompt/unregister-prompt.component';
import { environment } from 'src/environments/environment';
import { PrivacyDeclinePromptComponent } from '../../forms/prompt/privacy-decline-prompt/privacy-decline-prompt.component';

@Component({
  selector: 'app-device-registration-page',
  templateUrl: './device-registration-page.component.html',
  styleUrls: ['./device-registration-page.component.scss']
})
export class DeviceRegistrationPageComponent implements OnInit {
  access;
  project$;
  projectName;
  contacts;
  groups;
  id: any;
  subscription: any;
  tagHeader;
  number;
  smsEnabled: boolean;
  appEnabled: boolean;
  deviceModel: any;
  accepted: boolean;
  isLoading: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private changeLogger: ChangeLogService,
    private sBar: SnackBarService,
    private regDeviceService: RegistrationDeviceService,

  ) { }

  ngOnInit(): void {

    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];

    this._route.params.subscribe(params => {
      this.id = params.id;
    });
    this.project$ = this.getProjectDetails(this.id);
    this.subscription = this.project$.subscribe(res => {
      this.contacts = res.contacts;
      this.groups = res.groups;
      this.projectName = res.name;
    });

    let projectId = this.id;

    // API to get if appEnabled, accepted data privacy, device model, smsEnabled and contactNumber
    this.regDeviceService.registerDevice(projectId, eid).subscribe(res1 =>{
      this.accepted = res1.acceptedPrivacy;
      this.appEnabled = res1.appEnabled;
      this.smsEnabled = res1.smsEnabled;
      this.number = res1.numberPrefix + res1.number;

      if(res1.deviceModel){
        this.deviceModel = JSON.parse(res1.deviceModel).name;
      }
      
      if(this.accepted){
      this.tagHeader = "Please select your desired channel for receiving notifications :";
      }else {
        this.tagHeader = "Please read the Data Privacy statement before you proceed with the registration. ";
      }
      this.isLoading = false;
    });

  }

  getProjectDetails(id) {
    return this.projectService.getProjectById(id);
  }

  unregisterDevice() {
    let projectId = this.id;
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];

    
    let unregDeviceRef = this.dialog.open(UnregisterPromptComponent,{
      width: '500px',
    }).afterClosed().subscribe(res => {
      if(res.confirm){
        this.regDeviceService.unregisterDevice(projectId, eid).subscribe(res1 =>{
          this.appEnabled = false;
          window.location.href = `${environment.frontEndURL}/${this.id}/device-registration`
        })
      }
    })
  }

  unregisterNumber(){
    let projectId = this.id;
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];

    
    let unregDeviceRef = this.dialog.open(UnregisterPromptComponent,{
      width: '500px',
      
    }).afterClosed().subscribe(res => {
      if(res.confirm){
        this.regDeviceService.unregisterNumber(projectId, eid).subscribe(res1 =>{
          this.appEnabled = false;
          window.location.href = `${environment.frontEndURL}/${this.id}/device-registration`
        })
      }
    })
  }

  acceptPrivacy(action) {
    let projectId = this.id;
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];
    
    if(action === "Proceed"){
      this.regDeviceService.accptPrivacy(eid, projectId, action).subscribe(res => {
        window.location.href = `${environment.frontEndURL}/${this.id}/device-registration`
      })
    } else {
      this.dialog.open(PrivacyDeclinePromptComponent,{
        width: '550px'
      }).afterClosed().subscribe(res =>{
        if(res.confirm){
          this.regDeviceService.accptPrivacy(eid, projectId, action).subscribe(res =>{
            window.location.href = `${environment.frontEndURL}/${this.id}/device-registration/declined-data-privacy`
          })
        }
      })
    }
  }
}
