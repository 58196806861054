<div class="container-flex dark-bg project-view">
  <div class="conainer-bg">
    <div class="row">
      <div class="col-12 justify-content-start">
        <app-raven-logo></app-raven-logo>
      </div>
    </div>
    <div class="row my-3 white">
      <div class="col-1 bckBtn">
        <app-back-button></app-back-button>
      </div>
    </div>
    <div class="margin">
      <ng-container>
        <div class="row mb-n2 ml-4">
          <div
            class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white"
          >
            {{ project.name }}
          </div>
          <div
            class="col-12 mat-subheading-1 justify-content-start d-flex white"
            style="margin-top: 1%"
          >
            {{ project.alignment }}
          </div>
        </div>
        <ng-container>
          <div class="row d-flex cardContainer">
            <div class="col-4">
              <mat-card class="card">
                <mat-card-title>
                  <div class="mat-body-2 purple-dark">
                    <mat-icon [inline]="true">event_note</mat-icon>
                    Create Event
                  </div>
                </mat-card-title>
                <mat-card-content>
                  Send an event alert via SMS to inform or monitor project workforce.
                </mat-card-content>
                <mat-card-actions>
                  <button
                    mat-button
                    class="proceed-button"
                    color="primary"
                    (click)="createEventBtn()"
                  >
                    Get Started
                    <mat-icon [inline]>arrow_forward</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
            <div class="col-4">
              <mat-card class="card">
                <mat-card-title>
                  <div class="mat-body-2 purple-dark">
                    <mat-icon [inline]="true">people</mat-icon>
                    Manage Access
                  </div>
                </mat-card-title>
                <mat-card-content>
                  Manage user accessibility to ensure authorized alert delivery proceedings.
                </mat-card-content>
                <mat-card-actions>
                  <span class="comingSoon" color="primary"> Coming Soon </span>
                  <!-- <button mat-button class="proceed-button" color="primary">
                    <mat-icon [inline]>arrow_forward</mat-icon>
                  </button> -->
                </mat-card-actions>
              </mat-card>
            </div>
            <div class="col-4">
              <mat-card class="card">
                <mat-card-title>
                  <div class="mat-body-2 purple-dark">
                    <mat-icon [inline]="true">perm_contact_calendar</mat-icon>
                    Manage Contacts
                  </div>
                </mat-card-title>
                <mat-card-content>
                  Manage your application contacts and alert recipients easily.
                </mat-card-content>
                <mat-card-actions>
                  <button
                    mat-button
                    class="proceed-button"
                    color="primary"
                    (click)="manageContactsBtn()"
                  >
                    Manage
                    <mat-icon [inline]>arrow_forward</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
          <div class="row mt-2 table-container">
            <div class="col-lg-12">
              <mat-card>
                <mat-card-title
                  class="row mx-n3 d-flex mat-display-1 mb-n2 purple-dark"
                >
                  <div class="col mat-subheading-2 purple-dark">
                    Response Logs
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button
                      mat-flat-button
                      class="proceed-button"
                      color="primary"
                      (click)="downloadLog(project)"
                    >
                      <mat-icon>save_alt</mat-icon> Download Logs (csv)
                    </button>
                  </div>
                </mat-card-title>
                <mat-card-content>
                  <app-response-log-table></app-response-log-table>
                  <!-- <app-notification-log-table [projectId]="project._id"></app-notification-log-table> -->
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
