<div class="dark-bg">
  <br>
  <br>
  <div class="header white" *ngIf="destination !== 'peopleAccounting'">
      <p>RAVEN REGISTRATION</p>
  </div>
  <div class="header header-people-acc" *ngIf="destination === 'peopleAccounting'">
    <p>PEOPLE ACCOUNTING REGISTRATION</p>
  </div>

  <div class="white bckBtn">
      <app-back-button></app-back-button>
  </div>

  <div class="margin">
    <div class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white" *ngIf="(project$ | async) as project">
        {{project.name}}
    </div>
    <div class="col-12 mat-subheading-1 justify-content-start d-flex white" style="margin-top: 1%;" *ngIf="(project$ | async) as project">
        {{project.alignment}}
    </div>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title class="mat-subheading-2 cardHeader purple-dark" style="font-size: 35px;">{{tagHeader}}</mat-card-title>
        <mat-card-subtitle class="subCardHeader">{{description}}</mat-card-subtitle>
      </mat-card-header>

      <mat-divider style= "margin-top: 2%;" insert></mat-divider>

      <div class = "row" style="margin-top: 5.5%">
        <mat-card class="code-card">
          <form [formGroup] = 'otpFormGroup'>
            <mat-card-content class="cardContent">
              <p style="font-size:x-large;">
                ENTER YOUR MOBILE NUMBER
              </p>
              <br>
              <div class="col-12">
                <mat-form-field  [style.width.%]="15">
                  <mat-label>Country code</mat-label>

                  <mat-select formControlName="numberPrefix">
                    <mat-select-trigger>
                      <span>{{'+' + otpFormGroup.controls['numberPrefix'].value}}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let country of countries"
                      [value]="country.code.substring(1)">
                      {{country.name + ' (' + country.code + ')'}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field  [style.width.%]="25">

                  <mat-label>Phone Number</mat-label>
                  <input matInput type="text"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    maxlength="{{maxNumberLength}}"
                    formControlName="number"
                    placeholder="9xxxxxxxxx"
                    [style.width.%]="80"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')">
                  <mat-error *ngIf="this.otpFormGroup.controls['number'].invalid">
                    Not a valid <strong>phone number</strong></mat-error>
                  </mat-form-field>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <span *ngIf = "disableButton" style="margin-top: 10px;">Resend OTP after 10 seconds</span>
              <button mat-button class="proceed-button mb-2 mr-2"
                color="primary"
                type="button"
                (click)="onSendOTP()"
                [disabled]="this.otpFormGroup.controls['number'].invalid || disableButton" >
                {{hasSentOTP ? 'Resend OTP' : 'Send OTP'}}
              </button>
            </mat-card-actions>
          </form>
        </mat-card>

        <mat-card class="code-card" *ngIf="hasSentOTP">
          <form [formGroup] = 'otpFormGroup'>
            <mat-card-content class="cardContent">

              <p style="font-size:x-large;">
                ENTER THE 6 DIGIT CODE SENT TO YOUR PHONE
              </p>
              <mat-form-field appearance="outline" [style.width.%]="30">
                <mat-label>SIX DIGIT CODE</mat-label>
                <input matInput
                oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                maxlength="6"
                type = "text"
                [formControlName] = "'code'">
                <mat-error *ngIf="this.otpFormGroup.controls['code'].invalid">
                  Not a <strong>valid code</strong>
                </mat-error>
              </mat-form-field>

            </mat-card-content>
            <mat-card-actions>
              <button mat-button class="proceed-button mb-2 mr-2"
            type="button"
                color="primary"
                (click)="onSubmit()"
                [disabled]="this.otpFormGroup.controls['code'].invalid" >
                Submit
              </button>
            </mat-card-actions>
          </form>
        </mat-card>

        <div class="cnclBtn">
          <button mat-flat-button
            type = "button"
            color="primary"
            (click)="onCancel('people-accounting-registration')">
            Cancel
          </button>
        </div>
      </div>
  </mat-card>
  </div>
</div>
