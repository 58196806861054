<div class="dark-bg">
    <br>
    <br>
    <div class="header white">
      <p>RAVEN REGISTRATION</p>
    </div>
    <div class="white bckBtn">
        <app-back-button></app-back-button>
    </div>

    <div class="margin">
        <div class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white" *ngIf="(project$ | async) as project">
            {{project.name}}
          </div>
          <div class="col-12 mat-subheading-1 justify-content-start d-flex white" style="margin-top: 1%;" *ngIf="(project$ | async) as project">
            {{project.alignment}}
          </div>
          <mat-card class="card">
            <mat-card-header>
                <mat-card-title class="mat-subheading-2 cardHeader purple-dark" style="font-size: 35px;">{{tagHeader}}</mat-card-title>
                <mat-card-subtitle class="subCardHeader">{{description}}</mat-card-subtitle>
            </mat-card-header>

            <mat-divider style= "margin-top: 2%;" insert></mat-divider>

            <div class = "successMessage" style="margin-top: 5.5%">
                <mat-icon class="checkIcon">check_circle_outline</mat-icon>
                <p>Registration Successful!</p>
            </div>
            <div class="cnclBtn">
                <button mat-button
                  class="proceed-button"
                  type = "button"
                  color="primary"
                  (click)="backToDevRegPage()" >
                  Back
                </button>
            </div>
        </mat-card>
    </div>
</div>
