<div class="container-flex">
  <div class="row">
    <div class="col-11 purple-dark mat-subheading-2">
      Confirm Delete
    </div>
    <div class="col-1 px-2">
      <button type="button" mat-icon-button class="mt-n2" (click)="dialogRef.close({confirm: false})">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <!-- <div class="row col">
    Are you sure you want to delete:
  </div>
  <div class="row col">
    <pre [innerHTML]="data | json"></pre>
  </div> -->
  <div class="row">

    <div class="col">
      <button mat-flat-button
        type="button"
        color="warn"
        (click)="dialogRef.close({confirm: true})">
        Delete
      </button>
    </div>

  </div>
</div>
