import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Usecase } from '../models/usecase.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Scenario } from 'src/app/models/scenario.model';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  private updatedArray = new Subject<Scenario[]>();
  private scenarios: Scenario[] = [];

  updateListener = () => this.updatedArray.asObservable();

  readonly baseUrl = environment.apiURL + '/scenario';

  constructor(private http: HttpClient) { }

  createScenario(usecaseId, scenario: Scenario): Observable<Scenario>{
    let url = `${this.baseUrl}/create`;
    const params = new HttpParams().set('usecaseId', usecaseId);
    return this.http.post<Scenario>(url, scenario, {params});
  }

  getScenarios(usecaseId): Subscription {
    const params = new HttpParams().set('usecaseId', usecaseId);
    return this.http.get<Scenario[]>(this.baseUrl, {params})
      .subscribe(res => {
        this.scenarios = res;
        this.updatedArray.next([...res]);
      });
  }

  updateScenario(scenario: Scenario): Observable<Scenario> {
    let url = `${this.baseUrl}/${scenario._id}/update`
    return this.http.put<Scenario>(url, scenario);
  }

  deleteScenario(usecaseId,scenarioId): Observable<{ msg: string, id: string } | string>{
    let url = `${this.baseUrl}/${scenarioId}/delete`;
    const params = new HttpParams().set('usecaseId', usecaseId);
    return this.http.delete<{ msg: string, id: string } | string>(url,{params});
  }

  getScenariobyId(id): Observable<Scenario>{
    let url = `${this.baseUrl}/${id}`;
    return this.http.get<Scenario>(url);
  }

  updateView(action: string, scenario?: Scenario, scenarios?: Scenario[]) {
    if(action.toLowerCase() == 'add') {
      this.scenarios.push(scenario)
    }
    else if(action.toLowerCase() == 'delete') {
      this.scenarios = scenarios;
    }
    else if(action.toLowerCase() == 'edit') {
      const updatedArr = [...this.scenarios];
      const oldIndex = updatedArr.findIndex((s) => s._id === scenario._id);
      updatedArr[oldIndex] = scenario;
      this.scenarios = updatedArr;
    }

    this.updatedArray.next([...this.scenarios]);
  }
}
