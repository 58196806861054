import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PeopleAccountingService {
  constructor(private http: HttpClient) {}

  readonly baseURL = environment.apiURL;
  readonly awsURL = environment.awsApiURL;
  readonly awsApiKey = environment.awsApiKey;

  getHeaders() {
    // Configure AWS authentication headers if required
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Api-Key': this.awsApiKey,
    });
    return headers;
  }

  //people accounting main APIs
  getAcctgProjects() {
    const url = `${this.awsURL}/people-accounting/projects`;
    const headers = this.getHeaders();
    return this.http.get<any>(url, { headers });
  }

  responseLogs(pageNumber, pageSize, active, direction, projectId) {
    const url = `${this.awsURL}/people-accounting/response_logs`;
    const headers = this.getHeaders();

    let queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}&active=${active}&direction=${direction}&projectId=${projectId}`;
    return this.http.get<any>(`${url}` + queryParams, { headers });
  }

  getAcctgProjectDetails(id) {
    const url = `${this.awsURL}/people-accounting/project_details`;
    const headers = this.getHeaders();

    let requestBody = {
      id: id,
    };
    return this.http.post<any>(url, requestBody, { headers });
  }

  //create event lambda APIs
  getEventDetails() {
    const url = `${this.awsURL}/people-create-event/get-event-details`;
    const headers = this.getHeaders();
    return this.http.get<any>(url, { headers });
  }

  getEventMsgTemplate() {
    const url = `${this.awsURL}/people-create-event/get-event-msg-template`;
    const headers = this.getHeaders();
    return this.http.get<any>(url, { headers });
  }

  getDraftedEvents() {
    const param = `/people-create-event/get-event-details?sentStatus=false`;
    const url = `${this.awsURL}${param}`;
    const headers = this.getHeaders();
    return this.http.get<any>(url, { headers });
  }

  saveEventDraft(requestBody) {
    const url = `${this.awsURL}/people-create-event/save-event-draft`;
    const headers = this.getHeaders();
    return this.http.post<any>(url, requestBody, { headers });
  }

  sendAlertSMS(requestBody) {
    const url = `${this.awsURL}/people-infobip/sendsmsalert`;
    const headers = this.getHeaders();
    return this.http.post<any>(url, requestBody, { headers });
  }

  getContacts(id) {
    const url = `${this.awsURL}/people-manage-contacts/getcontacts?projectId=${id}`;
    const headers = this.getHeaders();

    return this.http.get<any>(url, { headers });
  }

  addNewContact(requestBody) {
    const url = `${this.awsURL}/people-manage-contacts/addcontact`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  updateContact(requestBody) {
    const url = `${this.awsURL}/people-manage-contacts/updatecontact`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  deleteContact(id) {
    const url = `${this.awsURL}/people-manage-contacts/deletecontact/${id}`;
    const headers = this.getHeaders();

    return this.http.delete<any>(url, { headers });
  }

  getContactGroups(id, categ) {
    const url = `${this.awsURL}/people-manage-contacts/getcontactgroups?projectId=${id}&category=${categ}`;
    const headers = this.getHeaders();

    return this.http.get<any>(url, { headers });
  }

  addNewGroup(requestBody) {
    const url = `${this.awsURL}/people-manage-contacts/addcontactgroup`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  updateGroup(requestBody) {
    const url = `${this.awsURL}/people-manage-contacts/updatecontactgroup`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  deleteGroup(projectId, id) {
    const url = `${this.awsURL}/people-manage-contacts/deletecontactgroup/${id}?projectId=${projectId}`;
    const headers = this.getHeaders();

    return this.http.delete<any>(url, { headers });
  }

  getCountryCodes() {
    const url = `${this.awsURL}/people-manage-contacts/getcountrycodes`;
    const headers = this.getHeaders();

    return this.http.get<any>(url, { headers });
  }

  downloadImportFile() {
    const fileUrl = `${this.awsURL}/people-manage-contacts/downloadimportfile`;
    const headers = this.getHeaders();

    return this.http
      .get(fileUrl, { headers, responseType: 'json' })
      .pipe(map((response: any) => response.data));
  }

  importContacts(requestBody) {
    const url = `${this.awsURL}/people-manage-contacts/importcontacts`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  getContactsEid(grpObjectId) {
    let reqBody = {
      groupId: grpObjectId,
    };
    const url = `${this.awsURL}/people-create-event/get-contacts-eid`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }

  downloadResponseLogs(projectId) {
    // console.log(projectId)
    const url = `${this.awsURL}/people-accounting/response_logs_table`;
    const headers = this.getHeaders();
    let queryParams = `?projectId=${projectId._id}`;

    return this.http.get<any>(`${url}` + queryParams, { headers });
  }

  //people accounting number registration
  dataPrivacy(eid, projectId, action){
    let reqBody = {
      projectId: projectId,
      eid: eid,
      action: action
    };
    const url = `${this.awsURL}/people-registration/data_privacy`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }

  unregisterNumber(projectId, eid){
    let reqBody = {
      projectId : projectId,
      eid :eid
    };
    const url = `${this.awsURL}/people-registration/unregister_number`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }

  registerNumber(number, numberPrefix, eid, projectId, resendStatus){
    let requestBody = {
      projectId: projectId,
      eid: eid,
      number: number,
      numberPrefix: numberPrefix,
      resendStatus: resendStatus
    }
    const url = `${this.awsURL}/people-registration/register_number`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, requestBody, { headers });
  }

  checkRegistration(projectId, eid){
    let reqBody = {
      projectId : projectId,
      eid :eid
    };
    const url = `${this.awsURL}/people-registration/check_registration`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }

  validateCode(eid, projectId, registrationCode?){
    let reqBody = {
      eid: eid,
      projectId : projectId,
      registrationCode: registrationCode
    };
    const url = `${this.awsURL}/people-registration/validate_sms_code`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }

  sendEmailInvite(eid, projectId) {
    let reqBody = {
      recipientsEid: eid,
      projectId : projectId
    };
    const url = `${this.awsURL}/people-registration/send_email`;
    const headers = this.getHeaders();

    return this.http.post<any>(url, reqBody, { headers });
  }
}
