<div class="container-flex">
  <div class="row">
    <div class="col-10 purple-dark mat-subheading-2" style="font-size:20px">
      Do you want to unregister?
    </div>
    <div class="col-1 px-2 ">
      <button type="button" mat-icon-button class="mt-n2 btnFormat" (click)="dialogRef.close({confirm: false})">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button mat-flat-button
        type="button"
        color="warn"
        (click)="dialogRef.close({confirm: true})">
        Confirm
      </button>
    </div>
  </div>
</div>
