<div class="container-flex">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    class="w-100 px-3"
    *ngIf="!isLoading"
  >
    <!-- Group ID Column -->
    <ng-container matColumnDef="groupId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Group ID</th>
      <td mat-cell *matCellDef="let element">{{ element.groupId }}</td>
    </ng-container>

    <!-- Group Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Action -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button
          [matTooltip]="editTooltip"
          color="primary"
          mat-icon-button
          (click)="openEditGroup('edit', element)"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          [matTooltip]="deleteTooltip"
          color="warn"
          mat-icon-button
          (click)="onDeleteGroup('group', element)"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <table style="width: 100%">
    <tr *ngIf="!dataSource.data.length && !isLoading">
      <td
        class="xs-txt text-center pt-10"
        style="background-color: white; padding-top: 15px"
        [attr.colspan]="9"
      >
        No data available
      </td>
    </tr>
  </table>

  <table class="w-100">
    <tr *ngIf="isLoading">
      <td class="pt-2 px-3">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 'border-radius': '5', height: '30px' }"
        ></ngx-skeleton-loader>
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
