import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RavenLogoComponent } from './raven-logo.component';



@NgModule({
  declarations: [RavenLogoComponent],
  imports: [
    CommonModule
  ],
  exports: [RavenLogoComponent]
})
export class RavenLogoModule { }
