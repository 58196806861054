// import { Component } from '@angular/core';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
// import { Observable, of } from 'rxjs';
// import { map, shareReplay } from 'rxjs/operators';
// import { RavenAuthService } from 'src/app/service/raven-auth.service';

// @Component({
//   selector: 'app-nav',
//   templateUrl: './nav.component.html',
//   styleUrls: ['./nav.component.scss']
// })
// export class NavComponent {

//   isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
//     .pipe(
//       map(result => result.matches),
//       shareReplay()
//     );

//   constructor(private breakpointObserver: BreakpointObserver,
//     private rAuthService: RavenAuthService) {}

//   currentUser = this.rAuthService.getUser();
//   isAdmin: Observable<boolean> = of(false);

//   ngOnInit() {
//     this.isAdmin = this.rAuthService.isAdmin(this.currentUser.eid);
//   }

// }
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RavenAuthService } from 'src/app/service/raven-auth.service';
import { BackAuthService } from 'src/app/service/back-auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private rAuthService: RavenAuthService
  ) {}
  hasAccess: boolean = false;

  currentUser = this.rAuthService.getUser();
  isAdmin: boolean = false;

  ngOnInit() {
    // this.isAdmin = this.rAuthService.isAdmin(this.currentUser);
    this.rAuthService.isAdmin(this.currentUser).subscribe(res => {
      this.isAdmin = res.isAdmin;
    })
  }
}
