<div class="container-flex dark-bg">
  <div class="container-bg">
    <div class="row">
      <div class="col">
        <app-raven-logo></app-raven-logo>
      </div>
      <div
        class="col-lg-12 px-4 my-4 mat-display-1 justify-content-center d-flex white"
        style="text-align: center"
      >
        Track event alerts of your project workforce today!
      </div>
      <div
        class="col-lg-12 mat-subheading-1 justify-content-center d-flex white"
      >
        Real-time Alert and Voice Enabled Notification tool
      </div>
    </div>

    <div class="row col-lg-12 container-flex row">
      <ng-container *ngFor="let project of projects">
        <div class="col-4 mt-3">
          <mat-card>
            <mat-card-title>
              <div class="mat-body-2 purple-dark">
                {{ project.name }}
              </div>
            </mat-card-title>
            <mat-card-subtitle>
              {{ project.alignment }}
            </mat-card-subtitle>
            <mat-card-content>
              Active Events: {{ project.events }}
            </mat-card-content>
            <mat-card-footer>
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                (click)="onProceed(project._id)"
              >
                EXPLORE
                <mat-icon [inline]="true">arrow_forward</mat-icon>
              </button>
            </mat-card-footer>
          </mat-card>
        </div>
      </ng-container>
    </div>

    <div>
      <!-- <mat-spinner class="loader-spinner" mode="indeterminate" [diameter]="200" *ngIf="isLoading"></mat-spinner> -->
    </div>
  </div>
  <div>
    <mat-spinner class="loader-spinner" mode="indeterminate" [diameter]="200" *ngIf="isLoading"></mat-spinner>
  </div>
</div>
