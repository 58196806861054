import { Subject, Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// import { format } from 'date-fns';
// import * as XLSX from 'sheetjs-style';
// import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-response-log-table',
  templateUrl: './response-log-table.component.html',
  styleUrls: ['./response-log-table.component.scss'],
})
export class ResponseLogTableComponent implements OnInit {
  logsLength: number;
  pageSizeOptions = ['10', '25', '50'];
  isLoading = false;
  currentPage;
  recordsPerPage: any = 10;
  pageIndex: any = 0;
  tableRecords: number = 0;
  paginatorEnabled: boolean;
  columnHeader;
  objectKeys = Object.keys;
  sortActive = 'dateTime';
  sortDirection = 'desc';
  id;
  paginator: MatPaginator;
  sort: MatSort;

  displayColumns = {
    dateTime: 'Date & Time',
    eid: 'Recipient',
    message: 'Message',
    timeDifferenceInSeconds: 'Response Time',
  };

  constructor(
    private peopleAccountingService: PeopleAccountingService,
    private snackbar: MatSnackBar,
    public form: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute
  ) {}

  dataSource = new MatTableDataSource<any>();
  //@ViewChild(MatSort) sort: MatSort;
  //@ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.id = params.id;
    });
    this.dataSource.data = [];
    this.getResponseLogs(this.sortDirection, this.sortActive, "initialLoad");
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  getResponseLogs(direction, active, type) {
    this.isLoading = type === "initialLoad" ? true : false;
    this.peopleAccountingService
      .responseLogs(
        this.pageIndex,
        this.recordsPerPage,
        active,
        direction,
        this.id
      )
      .subscribe((res) => {
        console.log(res)
        this.dataSource.data = res.data.responses;
        this.tableRecords = res.data.totalRecords;
        this.isLoading = false;
      });
  }

  changedPage(event) {
    this.dataSource.data = [];
    this.recordsPerPage = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getResponseLogs(this.sortDirection, this.sortActive, "");
  }

  isEllipsisActive(e: HTMLElement): boolean {
    return e ? e.offsetWidth < e.scrollWidth : false;
  }

  sortData(sort: Sort) {
    this.sortDirection = sort.direction;
    this.sortActive = sort.active;
    this.getResponseLogs(this.sortDirection, this.sortActive, "");
  }
}
