import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-people-acc-contact-form',
  templateUrl: './people-acc-contact-form.component.html',
  styleUrls: ['./people-acc-contact-form.component.scss'],
})
export class PeopleAccContactFormComponent implements OnInit {
  dialogTitle: string = '';
  isEditContact: boolean = false;
  contactGroups: any[];
  selectable = true;
  removable = true;
  addOnBlur = true;
  contact = {
    eid: '',
    tag: '',
    groups: [],
  };
  filteredGroups: Observable<any[]>;

  @ViewChild('groupList') groupList: MatChipList;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PeopleAccContactFormComponent>,
    private fb: FormBuilder,
    private peopleAccountingService: PeopleAccountingService,
    private _snackbar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  contactForm: FormGroup;

  ngOnInit(): void {
    this.dialogTitle = this.data.formTitle;
    this.isEditContact = this.data.formTitle.includes('EDIT');
    this.contactGroups = this.data.groupDropdownData;
    const contactData = this.data.tableContactData;

    this.contact.eid = contactData ? contactData.eid : '';
    this.contact.groups =
      contactData && contactData.group ? contactData.group : [];

    this.contactForm = this.fb.group({
      eid: [this.contact.eid, [Validators.required, Validators.maxLength(20)]],
      tag: [
        contactData ? contactData.tag : this.contact.tag,
        [Validators.required, Validators.maxLength(20)],
      ],
      groupInput: [null],
      groups: [
        contactData ? contactData.group : this.contact.groups,
        [Validators.required],
      ],
    });

    this.filteredGroups = this.contactForm.get('groupInput').valueChanges.pipe(
      startWith(''),
      map((value) => this.groupFilter(value))
    );
  }

  groupFilter(value: any) {
    const filterValue =
      value === null || value instanceof Object ? '' : value.toLowerCase();
    const matches = this.contactGroups.filter((group) =>
      group.name.toLowerCase().includes(filterValue)
    );
    const formValue = this.contactForm.get('groups').value;
    return formValue === null
      ? matches
      : matches.filter((x) => !formValue.find((y) => y.groupId === x.groupId));
  }

  addGroup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      const matches = this.contactGroups.filter(
        (group) => group.name.toLowerCase() === value
      );
      const formValue = this.contactForm.get('groups').value;
      const matchesNotYetSelected =
        formValue === null
          ? matches
          : matches.filter(
              (x) => !formValue.find((y) => y.groupId === x.groupId)
            );
      if (matchesNotYetSelected.length === 1) {
        this.contact.groups.push(matchesNotYetSelected[0]);
        this.contactForm.get('groups').setValue(this.contact.groups);
        this.contactForm.get('groupInput').setValue('');
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  selectGroup(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      return;
    }

    const value = event.option.value;

    let exist = this.contact.groups.some((group) => {
      group.name === value.name;
    });

    if (value && value instanceof Object && !exist) {
      this.contact.groups.push(value);
      this.contactForm.get('groups').setValue(this.contact.groups);
      this.contactForm.get('groupInput').setValue('');
    }
    this.groupInput.nativeElement.blur();
  }

  removeGroup(group: any) {
    const index = this.contact.groups.indexOf(group);
    if (index >= 0) {
      this.contact.groups.splice(index, 1);
      this.contactForm.get('groups').setValue(this.contact.groups);
      this.contactForm.get('groupInput').setValue('');
    }
  }

  closeAddContactForm(): void {
    this.dialogRef.close();
  }

  submitContact() {
    const formData = this.contactForm.value;

    let payload = {
      _id: this.data.tableContactData._id,
      eid: formData.eid,
      tag: formData.tag,
      groups: formData.groups,
      projectId: this.data.projectId,
    };
    if (this.dialogTitle.includes('ADD')) {
      delete payload._id;
      this.peopleAccountingService.addNewContact(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          //Send email invite
          this.peopleAccountingService.sendEmailInvite([payload.eid],payload.projectId).subscribe((res) => {
            if (res.statusCode === 200) {
              this.openSnackbar(`"${payload.eid}" has been added and an invite was sent successfully!`);
              this.data.getContacts();
            } else {
              this.openSnackbar(
                `An error occured while sending an email invite to "${payload.eid}"!`
              );
            }
          });

        } else if (res.statusCode === 409) {
          this.openSnackbar(
            `Unable to add "${payload.eid}". ${res.message}`
          );
        } else {
          this.openSnackbar(
            `An error occured while adding ${payload.eid} as new contact!`
          );
        }
      });
    } else {
      this.peopleAccountingService.updateContact(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          this.openSnackbar(`"${payload.eid}" has been updated successfully!`);
          this.data.getContacts();
        } else {
          this.openSnackbar(
            `An error occured while saving contact changes for ${payload.eid}!`
          );
        }
      });
    }

    this.closeAddContactForm();
  }

  enableSubmit(): boolean {
    return this.contactForm.valid;
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }
}
