import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RavenAuthService } from './raven-auth.service';
import { environment } from 'src/environments/environment';
import { DiffPatcher } from 'jsondiffpatch';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {

  diffPatcher = new DiffPatcher();
  initialBS: BehaviorSubject<any> = new BehaviorSubject(0);
  initialData: any = {};

  baseURL = environment.apiURL + '/change';

  initialObs = () => this.initialBS.asObservable();

  constructor(
    private http: HttpClient,
    private rAuth: RavenAuthService,
  ) {
    this.initialObs().subscribe(
      currentVal => {
        this.initialData = currentVal;
      }
    )
  }

  logChanges(newData,activity,id,projectId?): Observable<any> {

    var eid = this.rAuth.getUser().eid;
    var change = {
      eid,
      activity,
      id,
      projectId,
      changes: this.diffPatcher.diff(this.initialData,newData)
    }

    let url = `${this.baseURL}/log`
    return this.http.post(url,change);
  }

  setInitialData(formGroup: FormGroup) {
    this.initialBS.next(formGroup.valid ? formGroup.getRawValue() : {});
  }

  getChangeLog(): Observable<any>{
    let url = `${this.baseURL}/get`
    return this.http.get<any>(url);
  }
}
