import { Component, Input, OnInit } from '@angular/core';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

interface GroupsTable {
  groupId: string;
  name: string;
  action: string;
}

@Component({
  selector: 'app-people-acc-groups-table',
  templateUrl: './people-acc-groups-table.component.html',
  styleUrls: ['./people-acc-groups-table.component.scss'],
})
export class PeopleAccGroupsTableComponent implements OnInit {
  editTooltip = 'Edit group details';
  deleteTooltip = 'Delete group';
  tableGroupsData: GroupsTable[] = [];
  displayedColumns: string[] = ['groupId', 'name', 'action'];
  dataSource = new MatTableDataSource<GroupsTable>();
  paginator: MatPaginator;
  sort: MatSort;

  constructor(private _liveAnnouncer: LiveAnnouncer) {}

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @Input() openGroupForm: (type: string, data: any) => void;
  @Input() deleteRecord: (type: string, data: any) => void;
  @Input() groupsData: any[];
  @Input() isLoading: boolean;

  ngOnInit(): void {}

  ngOnChanges() {
    this.dataSource.data = this.groupsData ?? [];
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openEditGroup(type, data) {
    this.openGroupForm(type, data);
  }

  onDeleteGroup(type, data) {
    this.deleteRecord(type, data);
  }

  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
