import { Injectable } from '@angular/core';
import { countries } from './countries';

@Injectable({
  providedIn: 'root',
})

export class RavenCommon {

  toSentenceCase(string) {
    const result = string.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
    // example: 'theQuickBrownFox' => 'The Quick Brown Fox'
  }

  countryCodes: any = countries.slice(0,5);

  removeEmailDomain(email) {
    const at = email.lastIndexOf("@");
    var name = email.substring(0, at > -1 ? at : undefined);

    return name;
  }

  generateApiKey(): string {
    let alphaNumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+"
    let arrString = alphaNumeric.split("")
    let key = ""

    //generate random  string
    for (let i = 1; i <= 1649; i++) {
      key = key.concat(arrString[Math.floor(Math.random() * 63)])
    }

    return key;
  }

  getStatusString = (status: string) : string => {
    if(status=='prod') return 'Production';
    else if(status=='test') return 'Test';
    else if(status=='disabled') return 'Disabled';
  }

}

