import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import * as fileSaver from 'file-saver';
import { NotificationLogService } from 'src/app/service/notification-log.service';
import { Project } from 'src/app/models/project.model';
import { H } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-people-accounting-page',
  templateUrl: './people-accounting-page.component.html',
  styleUrls: ['./people-accounting-page.component.scss'],
})
export class PeopleAccountingPageComponent implements OnInit {
  project = {
    name: '',
    alignment: '',
  };
  id;

  constructor(
    private router: Router,
    private peopleAccountingService: PeopleAccountingService,
    private _route: ActivatedRoute,
    private logsService: NotificationLogService
  ) {}

  ngOnInit(): void {
    // this._route.params.subscribe((params) => {
    //   this.id = params.id;
    //   console.log(this.id);
    // });

    this._route.params.subscribe((params) => {
      this.id = params.id;
      this.peopleAccountingService.getAcctgProjectDetails(this.id).subscribe((res) => {
        this.project = res.data;
      })
    });
  }

  createEventBtn() {
    this.router.navigate([`home-people-accounting/${this.id}/create-event`]);
  }

  downloadLog(id) {
    this.peopleAccountingService
    .downloadResponseLogs(id)
    .subscribe((csvData) => {
      let objectData = csvData.data;
      if (csvData) {

        let header = Object.keys(objectData[0]).join(',');
        let headerArr =  header.split(",");
        let newArr = [];
        
        headerArr.map(h =>{
          if(h === "dateAndTime"){
            newArr.push("Date & Time");
          } else if (h === "responseTime"){
            console.log("else if")
            newArr.push("Response Time\n");
          } else {
            let newWord = h.charAt(0).toUpperCase() + h.slice(1);
            newArr.push(newWord);
          }
        })

        const csv = objectData.map(row => Object.values(row).join(',')).join('\n');
        const blob = new Blob([newArr.toString() + csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Response-logs.csv';
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  manageContactsBtn() {
    this.router.navigate([`home-people-accounting/${this.id}/manage-contacts`]);
  }
}
