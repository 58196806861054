import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcctgProject } from 'src/app/models/acctgProject.model';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-people-accounting-home-page',
  templateUrl: './people-accounting-home-page.component.html',
  styleUrls: ['./people-accounting-home-page.component.scss'],
})
export class PeopleAccountingHomePageComponent implements OnInit {
  projects: AcctgProject[];
  isLoading: boolean = true;


  constructor(
    private router: Router,
    private peopleAccountingService: PeopleAccountingService
  ) {}

  ngOnInit(): void {
    this.getAcctgProjectList();
  }

  getAcctgProjectList(){
    this.peopleAccountingService.getAcctgProjects().subscribe(res => {
      this.projects = res.data;
      this.isLoading = false;
    })
  }

  onProceed(projectId) {
    this.router.navigate([
      `home-people-accounting/${projectId}/people-accounting`,
    ]);
  }
}
