import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorMessageComponent } from './component/default/error-message/error-message.component';
import { PeopleAccountingHomePageComponent } from './component/pages/people-accounting-home-page/people-accounting-home-page.component';
import { PeopleAccountingPageComponent } from './component/pages/people-accounting-home-page/people-accounting-page/people-accounting-page.component';
import { PeopleAccountingHomePageRoutingModule } from './component/pages/people-accounting-home-page/people-accounting-home-page-routing.module';
import { PeopleAccountingRegistrationRoutingModule } from './component/pages/people-accounting-registration/people-accounting-registration-routing.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./component/pages/home-page/home-page.module').then(m => m.HomePageModule),
	canActivate: [MsalGuard]
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'home-people-accounting',
    loadChildren: () => import('./component/pages/people-accounting-home-page/people-accounting-home-page-routing.module').then(m => m.PeopleAccountingHomePageRoutingModule)

  },
  {
    path: 'admin',
    loadChildren: () => import('./component/pages/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'no-access',
    component: ErrorMessageComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
