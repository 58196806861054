import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PortalHostDirective } from '@angular/cdk/portal';



@Injectable({
  providedIn: 'root'
})
export class RegistrationDeviceService {

  constructor(private http: HttpClient) {

   }
   readonly baseURL = environment.apiURL;

   registerDevice(projectId, eid){
    let requestBody = {
      projectId: projectId,
      eid: eid
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/`, requestBody)
   }

   sendEmailInvite(tag,email,projectId,contactId,projectName,projectCode?){
    let requestBody = {
      tag: tag,
      email: email,
      projectId: projectId,
      contactId: contactId,
      name: projectName,
      frontEndURL: environment.frontEndURL
    }
    console.log(requestBody);
    return this.http.post<any>(`${this.baseURL}/mobile_registration/send_invite`, requestBody)
   }

   //Mobile Device Registration APIs
   unregisterDevice(projectId, eid){
    let requestBody = {
      projectId: projectId,
      eid : eid
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/unregister_device`, requestBody)
   }

   validateCode(eid, projectId, registrationCode?){
      let requestBody = {
        eid: eid,
        projectId : projectId,
        registrationCode: registrationCode
      }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/validate`, requestBody)
   }

   accptPrivacy(eid, projectId, action){
    let requestBody = {
      eid: eid,
      projectId : projectId,
      action : action
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/data_privacy`, requestBody)
   }

   //Sms and Call Registration APIs
   registerNumber(number, numberPrefix, eid, projectId, resendStatus){
    let requestBody = {
      projectId: projectId,
      eid: eid,
      number: number,
      numberPrefix: numberPrefix,
      resendStatus: resendStatus
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/register_number`, requestBody)
   }

   unregisterNumber(projectId, eid){
    let requestBody = {
      projectId: projectId,
      eid : eid
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/unregister_number`, requestBody)
   }

   validateSMSCode(eid, projectId, registrationCode?){
    let requestBody = {
      eid: eid,
      projectId : projectId,
      registrationCode: registrationCode
    }
    return this.http.post<any>(`${this.baseURL}/mobile_registration/validate_sms_code`, requestBody)
   }
    getAccess(eid) {
      let requestBody = {
        eid : eid
      }
      return this.http.post<any>(`${this.baseURL}/mobile_registration/registration_access`, requestBody)

    }
}
