import { Component, OnInit, Inject, OnDestroy, ɵSWITCH_RENDERER2_FACTORY__POST_R3__ } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {
  InteractionStatus,
  RedirectRequest,
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
// import { Logger, CryptoUtils } from 'msal';
import { RavenAuthService } from './service/raven-auth.service';
import { environment } from 'src/environments/environment';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router'
import { RegistrationDeviceService } from './service/device-registration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit , OnDestroy{
  title = 'RAVEN';
  isLoggedIn = false;
  isIframe = false;
  loginDisplay = false;
  hasAccess:boolean = false;
  user = this.ravenAuth.getUser();
  private readonly _destroying$ = new Subject<void>();

  constructor(
	@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
	private msalBroadcastService: MsalBroadcastService,
  private authService: MsalService,
	private ravenAuth: RavenAuthService,
	private router: Router,
  private regDeviceService: RegistrationDeviceService
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.setLoginDisplay();

	  this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result) => {
		const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.setLoginDisplay();
        // this.checkAccess();
     });

	  this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay()
        // this.checkAccess();
        if (!this.loginDisplay) {
          this.loginRedirect();
        }
     });

     if (this.loginDisplay) {
      this.checkAccess();
     }

    // this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
    //   this.checkAccess();
    // });

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay) {

      const user = this.authService.instance.getActiveAccount();
      this.ravenAuth.setUser(user.name,user.username);
      this.user = this.ravenAuth.getUser();
      this.ravenAuth.login({name:user.name, eid:user.username}).subscribe(res => {
        localStorage.setItem('accessToken', res['accessToken']);
        this.hasAccess = true;
        console.log('RAVEN Auth: Access Token set');
        this.checkAccess();
      });
    }
  }


  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

   logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  checkAccess() {
    let localstorage = JSON.parse(localStorage.getItem('loggedInUser'));
    this.regDeviceService.getAccess(localstorage.eid).subscribe((res2) => {
      console.log("URL", this.router.url);
      if (this.router.url.includes('device-registration')) {
        if (!res2.access.includes('registration')) {
          console.log('37');

          this.router.navigate([`/no-access`]);
        } else {
          console.log('41');

          this.router.navigate([this.router.url]);
        }
      } else if (this.router.url.includes('people-accounting-registration')) {
        if (!res2.access.includes('acctg-device-registration')) {
          this.router.navigate([`/no-access`]);
        } else {
          this.router.navigate([this.router.url]);
        }
      } else {
        if (!res2.access.includes('dashboard')) {
          console.log('47');

          this.router.navigate([`/no-access`]);
        } else {
          console.log('51');
          this.router.navigate([this.router.url]);
        }
      }
    });
  }
}
