import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';

@Component({
  selector: 'app-people-acc-group-form',
  templateUrl: './people-acc-group-form.component.html',
  styleUrls: ['./people-acc-group-form.component.scss'],
})
export class PeopleAccGroupFormComponent implements OnInit {
  dialogTitle = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PeopleAccGroupFormComponent>,
    private fb: FormBuilder,
    private peopleAccountingService: PeopleAccountingService,
    private _snackbar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  groupForm: FormGroup;

  ngOnInit(): void {
    this.dialogTitle = this.data.formTitle;
    const groupData = this.data.tableGroupData;
    this.groupForm = this.fb.group({
      groupId: [groupData ? groupData.groupId : ''],
      groupName: [groupData ? groupData.name : '', [Validators.required]],
    });
  }

  closeAddGroupForm(): void {
    this.dialogRef.close();
  }

  submitGroup() {
    const formData = this.groupForm.value;
    const payload = {
      projectId: this.data.projectId,
      groupId: formData.groupId,
      name: formData.groupName,
    };

    console.log('formData', formData);

    if (this.dialogTitle.includes('ADD')) {
      delete payload.groupId;
      this.peopleAccountingService.addNewGroup(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          this.openSnackbar(`${payload.name} has been added successfully!`);
          this.data.getContactGroups();
        } else if (res.statusCode === 409) {
          this.openSnackbar(
            `Unable to add "${payload.name}" group. Group name already exists!`
          );
        } else {
          this.openSnackbar(
            `An error occured while adding "${payload.name}" as new group!`
          );
        }
      });
    } else {
      this.peopleAccountingService.updateGroup(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          this.openSnackbar(
            `Group name has been updated to "${payload.name}" successfully!`
          );
          this.data.getContactGroups();
          this.data.getContacts();
        }  else if (res.statusCode === 409) {
          this.openSnackbar(
            `Unable to update group name into "${payload.name}". Group name already exists!`
          );
        } else {
          this.openSnackbar(
            `An error occured while saving changing "${payload.name}"!`
          );
        }
      });
    }

    this.closeAddGroupForm();
  }

  enableSubmit(): boolean {
    return this.groupForm.valid;
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }
}
