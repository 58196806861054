<div class="row">
  <div class="col" [formGroup]="contactForm">
    <div class="row d-flex">
      <div class="col-9 mat-subheading-2 purple-dark">{{ dialogTitle }}</div>
      <div class="col-3 d-flex justify-content-end">
        <button
          mat-icon-button
          color="basic"
          class="close-button"
          (click)="closeAddContactForm()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col px-4">
        <div class="mb-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>EID</mat-label>
            <input formControlName="eid" matInput [readonly]="isEditContact" />
            <mat-error
              *ngIf="contactForm.dirty && contactForm.get('eid').value == ''"
              >EID is required</mat-error
            >
            <mat-hint
              >EID is patterned in this format: "juan.dela.cruz"</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="mb-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Tag</mat-label>
            <input matInput formControlName="tag" type="text" />
            <mat-error
              *ngIf="contactForm.dirty && contactForm.get('tag').value == ''"
              >Tag is required</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Group</mat-label>
            <mat-chip-list #groupList formControlName="groups">
              <mat-chip
                class="contact-chip"
                *ngFor="let group of contact.groups"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeGroup(group)"
              >
                {{ group.name }}
                <button matChipRemove *ngIf="removable">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              <input
                #groupInput
                placeholder="Enter group..."
                [matChipInputFor]="groupList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addGroup($event)"
                [matAutocomplete]="groupAutocomplete"
                formControlName="groupInput"
              />
            </mat-chip-list>
            <mat-autocomplete
              #groupAutocomplete="matAutocomplete"
              (optionSelected)="selectGroup($event)"
            >
              <ng-container *ngFor="let group of filteredGroups | async">
                <mat-option class="contact-options" [value]="group">
                  {{ group.name }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <mat-error>At least 1 contact group is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col px-4 w-100">
        <div class="button-flex">
          <div class="d-flex justify-content-end">
            <button
              mat-flat-button
              class="btn-cancel mr-2"
              (click)="closeAddContactForm()"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              class="btn-submit"
              (click)="submitContact()"
              [disabled]="!enableSubmit()"
            >
              <mat-icon class="submit-icon">check_circle</mat-icon>&nbsp;Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
