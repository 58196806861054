import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UnregisterPromptComponent } from '../../forms/unregister-prompt/unregister-prompt.component';
import { environment } from 'src/environments/environment';
import { PrivacyDeclinePromptComponent } from '../../forms/prompt/privacy-decline-prompt/privacy-decline-prompt.component';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-people-accounting-registration',
  templateUrl: './people-accounting-registration.component.html',
  styleUrls: ['./people-accounting-registration.component.scss']
})
export class PeopleAccountingRegistrationComponent implements OnInit {

  id;
  project = {
    name: '',
    alignment: '',
  };
  access;
  tagHeader;
  number;
  smsEnabled: boolean;
  appEnabled: boolean;
  accepted: boolean = false;
  isLoading: boolean = true;
  isUnauthorized: boolean = false;

  constructor(
    private peopleAccountingService: PeopleAccountingService,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _snackbar: MatSnackBar

    ) { }


  ngOnInit(): void {
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];

    this._route.params.subscribe((params) => {
      this.id = params.id;
      this.peopleAccountingService.getAcctgProjectDetails(this.id).subscribe((res) => {
        this.project = res.data;
      })
    });

    this.checkRegistration(eid);

  }

  checkRegistration(eid) {
    this.peopleAccountingService.checkRegistration(this.id, eid).subscribe(res => {
      if(res.statusCode === 200) {
        this.accepted = res.data.result.acceptedPrivacy;
        this.smsEnabled = res.data.result.smsEnabled;
        this.number = res.data.result.numberPrefix + res.data.result.number;

        if(this.accepted){
          this.tagHeader = "Please select your desired channel for receiving alerts or notifications:";
        }else {
          this.tagHeader = "Please read the Data Privacy statement before you proceed with the registration. ";
        }
        this.isLoading = false;
      } else if (res.statusCode === 404) {
        this.isLoading = false;
        this.isUnauthorized = true;
      } else {
        this.isLoading = false;
        this.isUnauthorized = true;
        this.openSnackbar("An error occured while processing contact verification.");
      }
    });
  }

  unregisterNumber(){
    let projectId = this.id;
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];


    this.dialog.open(UnregisterPromptComponent,{
      width: '500px',
    }).afterClosed().subscribe(res => {
      if(res.confirm){
        this.peopleAccountingService.unregisterNumber(projectId, eid).subscribe(res1 =>{
          this.appEnabled = false;
          window.location.href = `${environment.frontEndURL}/${this.id}/people-accounting-registration`
        })
      }
    })
  }

  acceptPrivacy(action) {
    let projectId = this.id;
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];

    if(action === "Proceed"){
      this.peopleAccountingService.dataPrivacy(eid, projectId, action).subscribe(res => {
        if(res.statusCode === 200) {
          window.location.href = `${environment.frontEndURL}/${this.id}/people-accounting-registration/`
        }
      })
    } else {
      this.dialog.open(PrivacyDeclinePromptComponent,{
        width: '550px'
      }).afterClosed().subscribe(res =>{
        if(res.confirm){
          this.peopleAccountingService.dataPrivacy(eid, projectId, action).subscribe(res =>{
            window.location.href = `${environment.frontEndURL}/${this.id}/people-accounting-registration/declined-data-privacy`
          })
        }
      })
    }
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }
}
