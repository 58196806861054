// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL : 'https://raven.accenture.com:443',
  CLIENT_ID: '430c66e1-e36c-497e-9200-bd9900c60d8e', // This is your client ID
  AUTHORITY: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb', // This is your tenant ID
  REDIRECT_URI: 'https://raven-portal.accenture.com', // This is your redirect URI
  frontEndURL: 'https://raven-portal.accenture.com',
  awsApiURL: 'https://rspsio9tae.execute-api.ap-southeast-1.amazonaws.com/test',
  awsApiKey: 'Ho6KKgT5JJ89Vqj7xPjoD8GvLY6EqjsD9Lyhxmz2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.