<div class="dark-bg">
  <br />
  <br />
  <div class="header white">
    <p>PEOPLE ACCOUNTING REGISTRATION</p>
  </div>
  <br />

  <div class="margin" *ngIf="accepted && !isLoading">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title class="mat-subheading-2 cardHeader">{{
          tagHeader
        }}</mat-card-title>
      </mat-card-header>

      <mat-divider style="margin-top: 2%" insert></mat-divider>

      <div class="row" style="margin-top: 6%">
        <!-- SMS and Call Card -->
        <mat-card class="code-card">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title
              class="purple-dark"
              style="font-weight: bold; margin-left: -40px"
              >SMS / Call</mat-card-title
            >
            <mat-icon class="checkIcon" *ngIf="smsEnabled"
              >check_circle_outline</mat-icon
            >
          </mat-card-header>
          <mat-card-content style="display: flex">
            <mat-icon class="smsIcon">
              textsms <br />
              phone_in_talk</mat-icon
            >
            <p class="ellipsis">
              Receive alerts or notifications through telco partners.
              <br />
              <span *ngIf="number"
                ><b>Registered Number</b>: +{{ number }}</span
              >
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div *ngIf="smsEnabled">
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                [routerLink]="['./sms-and-call-registration']"
              >
                Update
              </button>
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                (click)="unregisterNumber()"
              >
                Remove
              </button>
            </div>
            <div *ngIf="!smsEnabled">
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                [routerLink]="['./sms-and-call-registration']"
              >
                Register
                <mat-icon [inline]="true">arrow_forward</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-card>
  </div>

  <!-- Data Privacy page/content -->
  <div class="invitation" *ngIf="!accepted && !isLoading && !isUnauthorized">
    <div class="welcome">
      <br />
      <p>Welcome!</p>
      <br />
      <p>
        You have been invited by <span class="project-name ">{{ project.name }}</span> to start receiving
        alerts/notifications from People Accounting powered by Real-time Alerts
        and Voice-Enabled Notification tool. You must enable at least one of the
        available channels.
        <br><br>
        By accepting the data privacy statement, you will be
        directed to the channel's selection.
      </p>
      <br />
    </div>
    <mat-card class="card">
      <br />
      <p style="margin-left: 20px; font-size: 20px">
        Please read the Data Privacy statement before you proceed with the
        registration.
      </p>
      <br />
      <mat-card class="privacyContent">
        <p>
          Please be aware that any personal data that you and others provide
          through this tool may be processed by Accenture for the purpose of
          sending alerts and notifications to your mobile phone in accordance
          with Accenture Data Privacy Statement.
          <br />
          <br />
          The protection of your personal data is very important to Accenture.
          Accenture is committed to keeping your personal data secure, and
          processing it in accordance with, applicable data protection laws and
          our internal policies, including Policies: 0090 - Data Privacy
          (English) (accenture.com).
          <br />
          <br />
          Your decision to provide your personal data to Accenture is voluntary.
          However, given that this tool requires personal data to function, you
          will not be able to use this tool if you do not provide your personal
          data.
          <br />
          <br />
          Before providing any personal data through this tool, Accenture
          invites you to carefully read GLOBAL DATA PRIVACY STATEMENT |
          Protecting Accenture, which includes important information on why and
          how Accenture is processing your personal data.
          <br />
          <br />
          Please also be aware that by using of this tool, you can still receive
          notifications even beyond your regular working hours and in such
          cases, there is no expectation from you to take any action outside of
          your working hours, holidays and rest day.
        </p>
      </mat-card>

      <mat-card-actions>
        <button
          mat-button
          class="proceed-button mb-2 mr-2"
          color="primary"
          (click)="acceptPrivacy('Decline')"
        >
          Decline
        </button>
        <button
          mat-button
          class="proceed-button mb-2 mr-2"
          color="primary"
          (click)="acceptPrivacy('Proceed')"
        >
          Proceed
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <!-- Unauthorized Access Contact -->
  <div class="invitation">
    <div class="welcome" *ngIf="isUnauthorized && !accepted && !isLoading">
      <br />
      <br />
      <br />
      <p class="unauthorized-access">
        <mat-icon class="block-icon">block</mat-icon> Unauthorized Access
        Detected!
      </p>
      <br />
      <p class="unauthorized-message">
        We're sorry, but it appears there was an unauthorized attempt accessing
        this page. Looks like you have not been invited for People Accounting
        Registration.
        <br /><br />
        If you believe this was an error, please contact your Project POC.
        <br /><br />
        Thank you!
      </p>
      <br />
    </div>
  </div>

  <div>
    <mat-spinner
      class="loader-spinner"
      style="margin: auto"
      mode="indeterminate"
      [diameter]="200"
      *ngIf="isLoading"
    ></mat-spinner>
  </div>
</div>
