import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { RavenAuthService } from 'src/app/service/raven-auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private injector: Injector) { }

  intercept(req, next) {
    let authService = this.injector.get(RavenAuthService);
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getToken()}`,
        "Cache-Control": 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      }
    });
    
    return next.handle(tokenizedReq);
  }
}
