import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PeopleAccContactFormComponent } from 'src/app/component/forms/people-acc-contact-form/people-acc-contact-form.component';
import { PeopleAccGroupFormComponent } from 'src/app/component/forms/people-acc-group-form/people-acc-group-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { Project } from 'src/app/models/project.model';
import { PeopleAccountingDeletePromptComponent } from 'src/app/component/forms/prompt/people-accounting-delete-prompt/people-accounting-delete-prompt.component';
import { MatSnackBar } from '@angular/material/snack-bar';

interface Group {
  group: string;
}

interface AcctgContact {
  contactId: string;
  eid: string;
  groups: Array<any>;
  number: string;
  numberPrefix: string;
  tag: string;
  _id: string;
}

interface AcctgGroup {
  groupId: string;
  groupName: string;
  _id: string;
}

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.scss'],
})
export class ManageContactsComponent implements OnInit {
  id;
  project = {
    name: '',
    alignment: '',
  };
  contactFormTitle = '';
  groupFormTitle = '';
  deleteFormTitle = '';
  selectedFile: FileList;
  importContactData: {
    eid: string;
    tag: string;
    groups: Group[];
  }[] = [];
  isFormEmpty = false;
  contactsData: AcctgContact[];
  groupsData: AcctgGroup[];
  countryCodesData: any[] = [];
  isLoadingContacts: boolean = false;
  isLoadingGroups: boolean = false;
  hasEmptyProperties: boolean = false;
  errorMessage: string = '';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private peopleAccountingService: PeopleAccountingService,
    private _route: ActivatedRoute,
    private _snackbar: MatSnackBar
  ) {}

  @ViewChild('fileInput') fileInput: ElementRef;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.id = params.id;
      this.peopleAccountingService
        .getAcctgProjectDetails(this.id)
        .subscribe((res) => {
          this.project = res.data;
        });
    });

    this.getContactsData();
    this.getGroupsData();
    this.getCountryCodes();
  }

  getContactsData() {
    this.isLoadingContacts = true;
    this.peopleAccountingService.getContacts(this.id).subscribe((res) => {
      if (res.statusCode === 200) {
        this.isLoadingContacts = false;
        this.contactsData = res.data;
      } else {
        this.isLoadingContacts = false;
        this.contactsData = [];
        //Snackbar: Something went wrong while retrieving data.
      }
    });
  }

  getGroupsData() {
    this.isLoadingGroups = true;
    this.peopleAccountingService.getContactGroups(this.id, 1).subscribe((res) => {
      if (res.statusCode === 200) {
        this.isLoadingGroups = false;
        this.groupsData = res.data;
      } else {
        this.isLoadingGroups = false;
        this.groupsData = [];
        //Snackbar: Something went wrong while retrieving data.
      }
    });
  }

  getCountryCodes() {
    this.peopleAccountingService.getCountryCodes().subscribe((res) => {
      if (res.statusCode === 200) {
        this.countryCodesData = res.data;
      } else {
        this.countryCodesData = [];
        this.openSnackbar('Failed to fetch data!');
      }
    });
  }

  openContactForm = (type: string, contactData: any): void => {
    this.contactFormTitle = type === 'add' ? 'ADD NEW CONTACT' : 'EDIT CONTACT';
    let dialogRef = this.dialog.open(PeopleAccContactFormComponent, {
      data: {
        formTitle: this.contactFormTitle,
        tableContactData: contactData,
        groupDropdownData: this.groupsData,
        codeDropdownData: this.countryCodesData,
        projectId: this.id,
        getContacts: () => {
          this.getContactsData();
        },
      },
      height: 'auto',
      width: '540px',
    });

    dialogRef.afterClosed();
  };

  openGroupForm = (type: string, groupData: any): void => {
    this.groupFormTitle = type === 'add' ? 'ADD NEW GROUP' : 'EDIT GROUP';
    let dialogRef = this.dialog.open(PeopleAccGroupFormComponent, {
      data: {
        formTitle: this.groupFormTitle,
        tableGroupData: groupData,
        projectId: this.id,
        getContactGroups: () => {
          this.getGroupsData();
        },
        getContacts: () => {
          this.getContactsData();
        },
      },
      height: 'auto',
      width: '540px',
    });

    dialogRef.afterClosed();
  };

  deleteRecord = (type: string, data: any) => {
    this.deleteFormTitle = type === 'contact' ? 'contact' : 'group';
    let deletePrompt = this.dialog
      .open(PeopleAccountingDeletePromptComponent, {
        data: {
          deleteFormTitle: this.deleteFormTitle,
        },
        width: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.confirm) {
          if (type === 'contact') {
            this.peopleAccountingService
              .deleteContact(data._id)
              .subscribe((res) => {
                if (res.statusCode === 200) {
                  this.openSnackbar(
                    `"${data.eid}" has been deleted successfully!`
                  );
                  setTimeout(() => {
                    this.getContactsData();
                  }, 3000);
                } else {
                  this.openSnackbar(
                    `An error occured while deleting ${data.eid}!`
                  );
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              });
          } else {
            const projectId = this.id;
            this.peopleAccountingService
              .deleteGroup(projectId, data._id)
              .subscribe((res) => {
                if (res.statusCode === 200) {
                  this.openSnackbar(
                    `"${data.name}" has been deleted successfully!`
                  );
                  setTimeout(() => {
                    this.getContactsData();
                    this.getGroupsData();
                  }, 3000);
                } else if (res.statusCode === 409) {
                  this.openSnackbar(
                    `Unable to delete '${data.name}'. There is an existing contact/s assigned to this group!`
                  );
                } else {
                  this.openSnackbar(
                    `An error occured while deleting ${data.name}!`
                  );
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              });
          }
        }
      });
  };

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    const csvFile = event.target.files;

    if (csvFile && csvFile.length > 0) {
      let convertedData = [];
      const file: File = csvFile.item(0);
      const reader: FileReader = new FileReader();

      reader.onload = (e) => {
        const csvData = e.target.result;
        convertedData = this.convertDataToArrObj(csvData);

        const extractData = convertedData.map((i) => {
          const group = i.Group.includes(';')
            ? i.Group.replace('\r', '').split(';')
            : i.Group.replace('\r', '').split();

          return {
            eid: i.EID,
            tag: i.Tag,
            groups: group,
          };
        });

        this.importContactData = extractData;
        this.isFormEmpty = this.importContactData.length < 1 && true;
        this.hasEmptyProperties = this.onCheckEmptyProps(
          this.importContactData
        );

        if (this.isFormEmpty && this.selectedFile) {
          this.errorMessage =
            'Unable to import file with empty values in the template.';
        } else {
          this.errorMessage =
            'Unable to import file with incomplete details in the template.';
        }
      };
      reader.readAsText(file);
    }
  }

  convertDataToArrObj(csv: any) {
    let lines = csv.split('\n');
    let result = [];
    let headers;
    headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      let obj = {};

      if (lines[i] == undefined || lines[i].trim() == '') {
        continue;
      }

      let words = lines[i].split(',');
      for (let j = 0; j < words.length; j++) {
        obj[headers[j].trim()] = words[j];
      }

      result.push(obj);
    }

    return result;
  }

  onCheckEmptyProps(arr: any) {
    return arr.some((obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          if (value === '' || value === null || value === undefined) {
            return true; // Found an object with an empty property
          }

          if (Array.isArray(value) && value[0] === '') {
            return true; // Found an object with an empty array property
          }
        }
      }
      return false; // No empty properties in this object
    });
  }

  importContacts() {
    const payload = {
      projectId: this.id,
      importPayload: this.importContactData ?? [],
    };

    const recipientsEid = payload.importPayload.map((i) => i.eid);

    this.peopleAccountingService.importContacts(payload).subscribe((res) => {
      if (res.statusCode === 200) {

        //Send email invite
        this.peopleAccountingService.sendEmailInvite(recipientsEid,payload.projectId).subscribe((res) => {
          if (res.statusCode === 200) {
            this.openSnackbar(`Contacts have been added and invites were sent successfully!`);
            this.getContactsData();
            this.getGroupsData();
          } else {
            this.openSnackbar(
              `An error occured while sending email invites to the new contacts!`
            );
          }
        });

        //Clear input file
        this.fileInput.nativeElement.value = '';
        delete this.selectedFile;
      } else {
        this.openSnackbar('An error occured while importing contacts!');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    });
  }

  downloadImportFile() {
    this.peopleAccountingService
      .downloadImportFile()
      .subscribe((csvData: string) => {
        console.log(csvData);
        if (csvData) {
          const blob = new Blob([csvData], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'peopleacctg-import-contacts.csv';
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000
    });
  }
}
