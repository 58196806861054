import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeLogService } from 'src/app/service/change-log.service';
import { RegistrationDeviceService } from 'src/app/service/device-registration.service';
import { ProjectService } from 'src/app/service/project.service';
import { SnackBarService } from 'src/app/service/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-app-reg-page',
  templateUrl: './mobile-app-reg-page.component.html',
  styleUrls: ['./mobile-app-reg-page.component.scss']
})
export class MobileAppRegPageComponent implements OnInit {
  project$;
  contacts;
  groups;
  id: any;
  subscription: any;
  tagHeader;
  description;
  sixDigitCode: String = "";

  constructor(
    private _route: ActivatedRoute,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private changeLogger: ChangeLogService,
    private sBar: SnackBarService,
    private fb: FormBuilder,
    private regDeviceService: RegistrationDeviceService,
    private _snackbar: MatSnackBar,
    private router: Router
  ) { }

  sixDigitCodeFormGroup: FormGroup = this.fb.group({
    code: new FormControl( '', [Validators.required,Validators.minLength(6),Validators.pattern('^[0-9]*$')])
  })

  ngOnInit(): void {

    this._route.params.subscribe(params => {
      this.id = params.id;
    });

    this.project$ = this.getProjectDetails(this.id);
    this.subscription = this.project$.subscribe(res => {
      this.contacts = res.contacts;
      this.groups = res.groups;

    });

    this.tagHeader = "Mobile Application";
    this.description  = "Send alerts via push notification through the mobile application.";

  }

  getProjectDetails(id) {
    return this.projectService.getProjectById(id);
  }

  onSubmit(){
    let localstorage = JSON.parse(localStorage.getItem("loggedInUser"));
    let eid = localstorage.eid && localstorage.eid.toString().split("@")[0];
    let projectId = this.id;


    let registrationCode = this.sixDigitCodeFormGroup.get("code").value;

    this.regDeviceService.validateCode(eid,projectId,registrationCode).subscribe({
      next: (res) => {
        window.location.href = `${environment.frontEndURL}/${this.id}/device-registration/mobile-app-registration/success`
      },
      error: (err) => {
        this.openSnackbar(err.error.message);
      }
    })

  }
  openSnackbar(message : string){
    this._snackbar.open(message, "Dismiss", {

      duration: 5000

    });
  }

  onCancel(){
    window.location.href = `${environment.frontEndURL}/${this.id}/device-registration`
  }
}
