<div class="container-flex">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    [style.width.%]="100"
    *ngIf="!isLoading"
  >
    <ng-container
      [matColumnDef]="tableData"
      *ngFor="let tableData of objectKeys(displayColumns)"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ displayColumns[tableData] }}
      </th>
      <ng-container>
        <td
          mat-cell
          class="overflow-ellipsis"
          *matCellDef="let element"
          #toolTipText
          matTooltip="{{ element[tableData] }}"
          matTooltipClass="full-display"
          [matTooltipDisabled]="!isEllipsisActive(toolTipText)"
        >
          {{ element[tableData] }}
        </td>
      </ng-container>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="objectKeys(displayColumns)"
      style="height: 48px"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: objectKeys(displayColumns)"
      style="height: 40px"
    ></tr>
  </table>
  <!-- length of data = 0 -->
  <table style="width: 100%">
    <tr *ngIf="!isLoading && !dataSource.data.length">
      <td
        class="xs-txt"
        style="text-align: center; background-color: white; padding-top: 15px"
        [attr.colspan]="9"
      >
        No data available
      </td>
    </tr>
  </table>

  <div class="mt-4">
    <ngx-skeleton-loader
      *ngIf="isLoading"
      count="10"
      [theme]="{ 'border-radius': '5', height: '30px' }"
    ></ngx-skeleton-loader>
  </div>
 
  <div fxLayout="column">
    <mat-paginator
      [length]="tableRecords"
      [pageSize]="recordsPerPage"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="changedPage($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
