import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy-decline-page',
  templateUrl: './privacy-decline-page.component.html',
  styleUrls: ['./privacy-decline-page.component.scss']
})
export class PrivacyDeclinePageComponent implements OnInit {
  destination: string = '';

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this.destination = this._route.snapshot.data.destination;
  }

}
