import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeLogService } from 'src/app/service/change-log.service';
import { RegistrationDeviceService } from 'src/app/service/device-registration.service';
import { ProjectService } from 'src/app/service/project.service';
import { SnackBarService } from 'src/app/service/snack-bar.service';
import { environment } from 'src/environments/environment';
import { RavenCommon } from '../../shared/ravenCommon';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';

@Component({
  selector: 'app-sms-call-reg-page',
  templateUrl: './sms-call-reg-page.component.html',
  styleUrls: ['./sms-call-reg-page.component.scss'],
})
export class SmsCallRegPageComponent implements OnInit {
  project$;
  contacts;
  groups;
  id: any;
  subscription: any;
  tagHeader;
  description;
  hasSentOTP: boolean = false;
  disableButton: boolean;
  currentNumber: String = '';
  maxNumberLength: any = 10;
  minNumberLength: any = 10;
  destination: string;

  constructor(
    private _route: ActivatedRoute,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private changeLogger: ChangeLogService,
    private sBar: SnackBarService,
    private router: Router,
    private fb: FormBuilder,
    private rc: RavenCommon,
    private regDeviceService: RegistrationDeviceService,
    private peopleAcctgService: PeopleAccountingService,
    private _snackbar: MatSnackBar
  ) {}

  otpFormGroup: FormGroup = this.fb.group({
    numberPrefix: new FormControl('63', Validators.required),
    number: new FormControl('', [
      Validators.required,
      Validators.minLength(this.minNumberLength),
      Validators.pattern('^[0-9]*$'),
    ]),
    code: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  public get numValue() {
    return this.otpFormGroup.get('number');
  }

  countries = this.rc.countryCodes;

  ngOnInit(): void {

    this.destination = this._route.snapshot.data.destination;

    this._route.params.subscribe((params) => {
      this.id = params.id;
    });

    this.project$ = this.getProjectDetails(this.id);
    this.subscription = this.project$.subscribe((res) => {
      console.log("PROJECT", this.project$);
      this.contacts = res.contacts;
      this.groups = res.groups;
    });

    this.tagHeader = this.destination === "peopleAccounting" ? "SMS / Call" : 'SMS and Call';
    this.description = this.destination === "peopleAccounting" ? "Receive alerts or notifications through telco partners." : 'Send notification through telco partners.';

    this.numValue.valueChanges.subscribe((res) => {
      if (this.currentNumber === res && this.currentNumber !== '') {
        this.hasSentOTP = true;
      } else {
        this.hasSentOTP = false;
      }
    });

    this.otpFormGroup.get('numberPrefix').valueChanges.subscribe((res) => {
      this.otpFormGroup.get('number').reset();

      let countryIndex =
        this.rc &&
        this.rc.countryCodes.findIndex(
          (c) => c.code === `+${this.otpFormGroup.get('numberPrefix').value}`
        );
      this.maxNumberLength = this.countries[countryIndex].length;
      this.minNumberLength = this.countries[countryIndex].length;

      this.otpFormGroup.get("number").setValidators([Validators.required,Validators.minLength(this.minNumberLength),Validators.pattern('^[0-9]*$')])
      this.otpFormGroup.get("number").updateValueAndValidity()
    })

  }

  getProjectDetails(id) {
    return this.projectService.getProjectById(id);
  }

  onSubmit() {
    let localstorage = JSON.parse(localStorage.getItem('loggedInUser'));
    let eid = localstorage.eid && localstorage.eid.toString().split('@')[0];
    let projectId = this.id;

    let registrationCode = this.otpFormGroup.get('code').value;

    if (this.destination === "peopleAccounting") {
      this.peopleAcctgService.validateCode(eid,projectId,registrationCode).subscribe({
        next: (res) => {
          if(res.statusCode === 200) {
            window.location.href = `${environment.frontEndURL}/${this.id}/people-accounting-registration/success`
          }else if (res.statusCode === 404) {
            this.openSnackbar("Unable to submit. Invalid registration code!");
          } else {
            this.openSnackbar("An error occured while validating the OTP!");
          }
        },
        error: (err) => {
          this.openSnackbar(err.error.message);
        }
      })
    } else {
      this.regDeviceService.validateSMSCode(eid,projectId,registrationCode).subscribe({
        next: (res) => {
          window.location.href = `${environment.frontEndURL}/${this.id}/device-registration/sms-and-call-registration/success`
        },
        error: (err) => {
          this.openSnackbar(err.error.message);
        }
      })
    }
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }

  onSendOTP() {
    let localstorage = JSON.parse(localStorage.getItem('loggedInUser'));
    let eid = localstorage.eid && localstorage.eid.toString().split('@')[0];
    let projectId = this.id;

    let numberPrefix = this.otpFormGroup.get('numberPrefix').value.toString();
    let number = this.otpFormGroup.get('number').value.toString();

    this.currentNumber = this.otpFormGroup.get('number').value;

    if (this.destination === "peopleAccounting") {
      this.peopleAcctgService
      .registerNumber(number, numberPrefix, eid, projectId, this.hasSentOTP)
      .subscribe({
        next: (res) => {
          if(res.statusCode === 200) {
            this.hasSentOTP = true;
            this.disableButton = true;
            setTimeout(() => {
              this.disableButton = false;
            }, 10000);
          } else {
            this.openSnackbar("An error occurred while sending an OTP!");
          }
        },
        error: (err) => {
          console.log(err);
          this.openSnackbar("Something went wrong while generating OTP. Please try again!");
        },
      });
    } else {
      this.regDeviceService
      .registerNumber(number, numberPrefix, eid, projectId, this.hasSentOTP)
      .subscribe({
        next: (res) => {
          this.hasSentOTP = true;
          this.disableButton = true;
          setTimeout(() => {
            this.disableButton = false;
          }, 10000);
        },
        error: (err) => {
          console.log(err);
          this.openSnackbar(err.error.message);
        },
      });
    }
  }

  onCancel(pageLocation){
    window.location.href = `${environment.frontEndURL}/${this.id}/${pageLocation}`
  }
}
