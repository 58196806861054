import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private sBar: MatSnackBar) { }

  open(type: string, successMsg: string) {
    // type is <Type> <Id> (ex. "Usecase UC_0000001", "Contact CT_0000002")
    var msg = type + ' ' + successMsg;

    this.sBar.open(msg,'close');
  }
}
