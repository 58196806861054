import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RavenAuthService {

  constructor(private http: HttpClient) { }
  private hasAccess = new BehaviorSubject<Boolean | String>('');
  private $hasAccess = this.hasAccess.asObservable();

  setUser(name,username) {
    const userObj = {
      name: name,
      eid: username
    }
    localStorage.setItem('loggedInUser',JSON.stringify(userObj));
  }

  getToken(){
    return localStorage.getItem('accessToken');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('loggedInUser'));
  }

  isAdmin(eid): Observable<any> {
    let url = environment.apiURL + `/admin?eid=${eid.eid}`;
    return this.http.get(url);
  }

  login(account) {
    let url = environment.apiURL + `/login/`;
    return this.http.post(url,account);
  }

  logout(account) {
    let url = environment.apiURL + `/logout/`;
    return this.http.post(url,account);
  }
  
  public checkIfHasAccess(): Observable<boolean> {
    return new Observable((observer) => {
      if (localStorage.hasOwnProperty('accessToken')) {
        let hasAccess = localStorage.hasOwnProperty('accessToken');
        observer.next(hasAccess);
      } else {
        this.$hasAccess.subscribe((res) => {
          if (res !== '') {
            observer.next(!!res);
          }
        });
      }
    })
  }
}


