import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-people-accounting-delete-prompt',
  templateUrl: './people-accounting-delete-prompt.component.html',
  styleUrls: ['./people-accounting-delete-prompt.component.scss'],
})
export class PeopleAccountingDeletePromptComponent implements OnInit {
  dialogTitle = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PeopleAccountingDeletePromptComponent>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.dialogTitle = this.data.deleteFormTitle;
  }
}
