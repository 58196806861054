
<mat-card class="card">
    <div class="row">
        <div class="col-10 purple-dark mat-subheading-2" style="margin-left: 15px;">
            {{ regStatus }}
        </div>
        <div class="col-1 px-2">
            <button type="button" mat-icon-button class="mt-n2 btnFormat" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div> 
    <mat-card-content style="margin-left: 30px">
        <p>{{regInstruction}}</p>
        <p>1. Open RAVEN application on your mobile device.</p>
        <p>2. Log in to your RAVEN account.</p>
        <p>3. Navigate to Register Device</p>
        <p>4. Enter the 6 digit code on your device.</p>
    </mat-card-content>
    <mat-divider inset></mat-divider>
    <div class="code-card" style="margin-left: 140px">
        <mat-card class="code-card" align="center" >
            <mat-card-title class="mat-body-2">
                {{regCode}}
            </mat-card-title>
        </mat-card>
    </div>
</mat-card>




