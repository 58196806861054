<div class="row">
  <div class="col">
    <div class="row d-flex">
      <div class="col-9 mat-subheading-2 purple-dark">{{ dialogTitle }}</div>
      <div class="col-3 d-flex justify-content-end">
        <button
          mat-icon-button
          color="basic"
          class="close-button"
          (click)="closeAddGroupForm()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col px-4">
        <div [formGroup]="groupForm">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Group Name</mat-label>
            <input matInput type="text" formControlName="groupName" />
            <mat-error
              *ngIf="groupForm.dirty && groupForm.get('groupName').value == ''"
              >Group name is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col px-4 w-100">
        <div class="button-flex">
          <div class="d-flex justify-content-end">
            <button
              mat-flat-button
              class="btn-cancel mr-2"
              (click)="closeAddGroupForm()"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              class="btn-submit"
              (click)="submitGroup()"
              [disabled]="!enableSubmit()"
            >
              <mat-icon class="submit-icon">check_circle</mat-icon>&nbsp;Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
