import { Component, ElementRef, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PeopleAccountingService } from 'src/app/service/people-accounting.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { SnackBarService } from 'src/app/service/snack-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackButtonPromptComponent } from '../../../forms/prompt/back-button-prompt/back-button-prompt.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss'],
})

export class EventPageComponent implements OnInit {
  project = {
    name: '',
    alignment: '',
  };
  id;
  eventId = '';
  selectedDraft = '';
  selectedEventMsgTemp = '';
  eventName = '';
  expirationDateTime = '';
  eventMessage = '';
  isEventNameEmpty = false;
  isContactEmpty = false;
  isExpirationEmpty = false;
  isEventMessageEmpty = false;
  showRecipients = false;
  isActiveSaveDraft = false;
  isActiveSendSMS = false;
  viewOrHide = 'View';
  eventMsgTempDropdown: any[];
  draftsDropdown: any[];
  totalEid = [];
  totalEidCount;
  isLoading: boolean = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  selectedGroups: any[] = [];
  contactGroups = [];
  grpObjectId = [];
  back: boolean = false;
  isResponseEnabled: boolean = false;
  isResponseEmpty: boolean = false;
  responseFooter =
    'To reply, send "ACN <eventId> <response>" to 4629 for Smart or 2086 for Globe.';
  responseFormat = '';
  eventMsgFooter = '';
  minDate: string;

  filteredGroups: Observable<any[]>;
  @ViewChild('groupList') groupList: MatChipList;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private _route: ActivatedRoute,
    private peopleAccountingService: PeopleAccountingService,
    public fb: FormBuilder,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private location: Location
  ) {
    // Initialize minDate to the current date and time
    this.minDate = new Date().toISOString().slice(0, 16);
  }

  eventForm: FormGroup;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.id = params.id;
    });

    this.peopleAccountingService
      .getAcctgProjectDetails(this.id)
      .subscribe((res) => {
        this.project = res.data;
      });

    this.getContactGroups(this.id, 2);
    this.getDraftedEvents();
    this.getEventMessageTemplate();

    this.eventForm = this.fb.group({
      groupInput: [null],
      groups: [this.selectedGroups],
    });

    this.filteredGroups = this.eventForm.get('groupInput').valueChanges.pipe(
      startWith(''),
      map((value) => this.groupFilter(value))
    );

    this.eventForm.get('groups').valueChanges.subscribe((res1) => {
      this.grpObjectId = res1;

      this.peopleAccountingService
        .getContactsEid(this.grpObjectId)
        .subscribe((res2) => {
          this.totalEidCount = res2.data.count;
          this.totalEid = res2.data.eid;
        });
    });
  }

  groupFilter(value: any) {
    const filterValue =
      value === null || value instanceof Object ? '' : value.toLowerCase();
    const matches = this.contactGroups.filter((group) =>
      group.name.toLowerCase().includes(filterValue)
    );
    const formValue = this.eventForm.get('groups').value;
    return formValue === null
      ? matches
      : matches.filter((x) => !formValue.find((y) => y.groupId === x.groupId));
  }

  addGroup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      const matches = this.contactGroups.filter(
        (group) => group.name.toLowerCase() === value
      );
      const formValue = this.eventForm.get('groups').value;
      const matchesNotYetSelected =
        formValue === null
          ? matches
          : matches.filter(
              (x) => !formValue.find((y) => y.groupId === x.groupId)
            );
      if (matchesNotYetSelected.length === 1) {
        this.selectedGroups.push(matchesNotYetSelected[0]);
        this.eventForm.get('groups').setValue(this.selectedGroups);
        this.eventForm.get('groupInput').setValue('');
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  selectGroup(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      return;
    }

    const value = event.option.value;

    let exist = this.selectedGroups.some((group) => {
      group.name === value.name;
    });

    if (value && value instanceof Object && !exist) {
      this.selectedGroups.push(value);
      this.eventForm.get('groups').setValue(this.selectedGroups);
      this.eventForm.get('groupInput').setValue('');
    }
    this.groupInput.nativeElement.blur();
  }

  removeGroup(group: any) {
    const index = this.selectedGroups.indexOf(group);
    if (index >= 0) {
      this.selectedGroups.splice(index, 1);
      this.eventForm.get('groups').setValue(this.selectedGroups);
      this.eventForm.get('groupInput').setValue('');
    }

    if (this.selectedGroups.length < 1) {
      this.viewOrHide = 'View';
      this.showRecipients = false;
    }
  }

  getContactGroups(id, categ) {
    this.peopleAccountingService.getContactGroups(id, categ).subscribe((res) => {
      this.contactGroups = res.data;
      this.isLoading = false;
    });
  }

  getDraftedEvents() {
    this.peopleAccountingService.getDraftedEvents().subscribe((res) => {
      this.draftsDropdown = res.data;
    });
  }

  getEventMessageTemplate() {
    this.peopleAccountingService.getEventMsgTemplate().subscribe((res) => {
      this.eventMsgTempDropdown = res.data;
    });
  }

  onChangeEvent(field: string, value: any): void {
    if (field === 'selectedDraft') {
      this.selectedDraft = value;

      if (this.selectedDraft === 'newEvent') {
        this.eventId = '';
        this.onResetForm();
      } else {
        const getDraftDetails = this.draftsDropdown.filter(
          (draft) => draft.eventID === this.selectedDraft
        );
        this.eventId = getDraftDetails[0].eventID;
        this.eventName = getDraftDetails[0].eventName;
        this.eventMessage =
          getDraftDetails[0].message === 'null'
            ? ''
            : getDraftDetails[0].message;

        this.isResponseEnabled = getDraftDetails[0].isTwoWaySMS;
        this.eventMsgFooter = getDraftDetails[0].toReplyFooter;
        this.responseFormat = getDraftDetails[0].responseFormat;

        //Get Group Details
        const groupRecipient = getDraftDetails[0].recipients;
        if (this.contactGroups.length > 0 && groupRecipient.length > 0) {
          let recipientData = [];
          for (let groupId of groupRecipient) {
            const getRecipients = this.contactGroups.filter(
              (group) => group._id === groupId
            );

            if (getRecipients.length > 0) {
              recipientData.push(getRecipients[0]);
            }
          }
          this.selectedGroups = recipientData.length < 1 ? [] : recipientData;
        }
        this.peopleAccountingService
          .getContactsEid(this.selectedGroups)
          .subscribe((res2) => {
            this.totalEidCount = res2.data.count;
            this.totalEid = res2.data.eid;
          });

        this.expirationDateTime = this.onFormatExpiration(
          getDraftDetails[0].expiration.toString()
        );
      }
    } else if (field === 'selectedEventMsgTemp') {
      this.selectedEventMsgTemp = value;
      const gettemplateMessage = this.eventMsgTempDropdown.filter(
        (msgTemp) => msgTemp._id === this.selectedEventMsgTemp
      );
      this.eventMessage = gettemplateMessage[0].templateMessage;
    } else if (field === 'isResponseEnabled') {
      this.isResponseEnabled = value;
      if (!this.isResponseEmpty) {
        this.eventMsgFooter = '';
        this.responseFormat = '';
      }
    } else {
      this[field] = value;
    }
  }

  onKeyUpResponse(value: any): void {
    this.responseFormat = value;
    const updatedFooter = this.responseFooter.replace('<response>', value);
    this.eventMsgFooter = updatedFooter;
  }

  sendSMS() {
    this.isEventNameEmpty = this.eventName === '' ? true : false;
    this.isContactEmpty = this.selectedGroups.length === 0 ? true : false;
    this.isExpirationEmpty = this.expirationDateTime === '' ? true : false;
    this.isEventMessageEmpty = this.eventMessage === '' ? true : false;
    this.isResponseEmpty =
      this.isResponseEnabled && this.responseFormat === '' ? true : false;

    if (
      this.isEventNameEmpty === false &&
      this.isContactEmpty === false &&
      this.isExpirationEmpty === false &&
      this.isEventMessageEmpty === false &&
      this.isResponseEmpty === false
    ) {
      this.isActiveSendSMS = true;
      let payload = {
        eventId: this.eventId,
        projectId: this.id,
        eventName: this.eventName,
        groupIds: this.selectedGroups.map((i) => i._id),
        message: this.eventMessage,
        expiration: this.expirationDateTime,
        channel: 'SMS', //static value for now
        isTwoWaySMS: this.isResponseEnabled,
        toReplyFooter: this.isResponseEnabled ? this.eventMsgFooter : 'N/A',
        responseFormat: this.isResponseEnabled ? this.responseFormat : 'N/A',
      };

      this.peopleAccountingService.sendAlertSMS(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          this.onResetForm();
          this.getDraftedEvents();
          this.isActiveSendSMS = false;
          this.openSnackbar('SMS alert successfully sent!');
        } else {
          this.isActiveSendSMS = false;

          this.openSnackbar('An error occured while sending an SMS alert!');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    }
  }

  saveAsDraft() {
    this.isEventNameEmpty = this.eventName === '' ? true : false;
    this.isContactEmpty = false;
    this.isExpirationEmpty = false;
    this.isResponseEmpty =
      this.isResponseEnabled && this.responseFormat === '' ? true : false;
    this.isEventMessageEmpty =
      this.isResponseEnabled && this.eventMessage === '' ? true : false;

    if (
      this.isEventNameEmpty === false &&
      this.isResponseEmpty === false &&
      this.isEventMessageEmpty === false
    ) {
      this.isActiveSaveDraft = true;
      let payload = {
        eventId: this.eventId,
        projectId: this.id,
        eventName: this.eventName,
        groupIds: this.selectedGroups.map((i) => i._id),
        message: this.eventMessage,
        expiration: this.expirationDateTime,
        channel: 'SMS', //static value for now
        isTwoWaySMS: this.isResponseEnabled,
        toReplyFooter: this.isResponseEnabled ? this.eventMsgFooter : 'N/A',
        responseFormat: this.isResponseEnabled ? this.responseFormat : 'N/A',
      };

      this.peopleAccountingService.saveEventDraft(payload).subscribe((res) => {
        if (res.statusCode === 200) {
          this.getDraftedEvents();
          this.onResetForm();
          this.isActiveSaveDraft = false;
          this.openSnackbar('Event successfully saved as draft! ');
        } else {
          this.isActiveSaveDraft = false;
          this.openSnackbar('An error occured while saving an event as draft!');
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      });
    }
  }

  onFormatExpiration(expirationDate) {
    const inputDate = new Date(expirationDate);

    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(inputDate.getUTCDate()).padStart(2, '0');
    const hours = String(inputDate.getUTCHours()).padStart(2, '0');
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  onShowRecipients() {
    this.showRecipients = !this.showRecipients;
    this.viewOrHide = this.viewOrHide === 'View' ? 'Hide' : 'View';
  }

  onResetForm() {
    this.eventName = '';
    this.eventMessage = '';
    this.selectedGroups = [];
    this.expirationDateTime = '';
    this.responseFormat = '';
    this.totalEidCount = 0;
    this.totalEid = [];
    this.viewOrHide = 'View';
    this.showRecipients = false;
    this.eventMsgFooter = '';
    this.isResponseEnabled = false;
    this.responseFormat = '';
  }

  submitForm(): void {
    console.log(this.eventForm.get('groups'));
  }

  openSnackbar(message: string) {
    this._snackbar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }

  backButton() {
    this.dialog
      .open(BackButtonPromptComponent, {
        width: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.confirm) {
          this.location.back();
        }
      });
  }
}
