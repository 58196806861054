import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationLog } from '../models/notification-log.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NotificationLogService {
  getSmsCost(startDate: any, endDate: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  readonly baseUrl = environment.apiURL;

  getProjectLogs(projectID, page, pageSize, selectedDateRange?, action?, status?): Observable<any> {
    let url = projectID
      ? `${this.baseUrl}/logs/${projectID}`
      : `${this.baseUrl}/logs/`;
    var params = new HttpParams()
      .set('page',page)
      .set('pageSize',pageSize)
      .set('selectedDateRange',selectedDateRange)
      .set('action',action)
      .set('status',status);

    const options = {params};

    return this.http.get(url, {...options});
  }


  getProjectAnalytics(dateRange, channel, projectID){
    let url = `${this.baseUrl}/logs/${projectID}/analyticsChannel/`;
    var params = new HttpParams()
    .set('dateRange', dateRange)
    .set('action', channel)

    const options = {params};

    return this.http.get<any>(url, {...options});
  }

  getProjectAnalyticsAll(dateRange, channel, projectID){
    let url = `${this.baseUrl}/logs/${projectID}/analyticsAll/`;
    var params = new HttpParams()
    .set('dateRange', dateRange)
    .set('action', channel)

    const options = {params};

    return this.http.get<any>(url, {...options});
  }

  getRunningBill(channel, dateRange, projectID){
    let url = `${this.baseUrl}/logs/${projectID}/getRunningBill/`;
    var params = new HttpParams()
    .set('action', channel)
    .set('dateRange', dateRange)    

    const options = {params};

    return this.http.get<any>(url, {...options});
  }

  downloadLogs(projectId,filename): Observable<any> {
    let url = `${this.baseUrl}/logs/${projectId}/download`;
    var params = new HttpParams()
      .set('filename', filename);

    const options = {params};

    return this.http.get(url, {...options, responseType: 'blob'});
  }
}
