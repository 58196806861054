<div class='card-content'>
  <mat-card class='mat-elevation-z8'>
    <mat-card-header>
      <img src="../../assets/images/acn-logo.png" class='logo'/>
    </mat-card-header>
    <mat-card-content>
      <h2>Sorry, you don't have access to this site.</h2>
      <!-- <p>The site is temporarily down.</p> -->
      To request an access, please send an email to: 
    <br>
    <div class="mat-subheading-2 purple-dark" style="font-size: 20px;">
    <a href="https://deliveryportal.accenture.com/dp-express/new-ticket">ATCP.DP.Tools.Team</a>
  </div>
    </mat-card-content>
    <br>
  </mat-card>
</div> 