<div class="container-flex">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    class="w-100 px-3"
    *ngIf="!isLoading"
  >
    <!-- EID Column -->
    <ng-container matColumnDef="eid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EID</th>
      <td mat-cell *matCellDef="let element">{{ element.eid }}</td>
    </ng-container>

    <!-- Masked Country Code + Contact Number Column -->
    <ng-container matColumnDef="maskedNumber" class="t-header">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Masked Number</th>
      <td mat-cell *matCellDef="let element" class="text-center" [style.color]="element.maskedNumber !== null ? 'initial' : '#0000008a'">{{ element.maskedNumber ?? "N/A" }}</td>
    </ng-container>

    <!-- Tag Column -->
    <ng-container matColumnDef="tag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
      <td mat-cell *matCellDef="let element" class="text-center">{{ element.tag }}</td>
    </ng-container>

    <!-- Group -->
    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Group
      </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-chip-list>
          <ng-container *ngFor="let group of element.group">
            <mat-chip selected color="primary" class="font-weight-normal">
              {{ group.name }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </td>
    </ng-container>

    <!-- SMS Column -->
    <ng-container matColumnDef="smsEnabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SMS / Call</th>
      <td
        mat-cell
        *matCellDef="let element"
        [style.color]="element.smsEnabled ? 'green' : 'red'"
        class="text-center"
      >
        {{ element.smsEnabled ? "Registered" : "Unregistered" }}
      </td>
    </ng-container>

    <!-- Action -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button
          [matTooltip]="editTooltip"
          color="primary"
          mat-icon-button
          (click)="openEditContact('edit', element)"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          [matTooltip]="deleteTooltip"
          color="warn"
          mat-icon-button
          (click)="onDeleteContact('contact', element)"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <table class="w-100">
    <tr *ngIf="!dataSource.data.length && !isLoading">
      <td
        class="xs-txt"
        style="text-align: center; background-color: white; padding-top: 15px"
        [attr.colspan]="9"
      >
        No data available
      </td>
    </tr>
  </table>

  <table class="w-100">
    <tr *ngIf="isLoading">
      <td class="pt-2 px-3">
        <ngx-skeleton-loader
          count="5"
          [theme]="{ 'border-radius': '5', height: '30px' }"
        ></ngx-skeleton-loader>
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
