<div class="dark-bg">
  <br />
  <br />
  <div class="header white">
    <p>RAVEN REGISTRATION</p>
  </div>
  <br />

  <div class="margin" *ngIf="accepted && !isLoading">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title class="mat-subheading-2 cardHeader">{{
          tagHeader
        }}</mat-card-title>
      </mat-card-header>

      <mat-divider style="margin-top: 2%" insert></mat-divider>

      <div class="row" style="margin-top: 6%">
        <!-- SMS and Call Card -->
        <mat-card class="code-card">
          <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title
              class="purple-dark"
              style="font-weight: bold; margin-left: -40px"
              >SMS and Call</mat-card-title
            >
            <mat-icon class="checkIcon" *ngIf="smsEnabled"
              >check_circle_outline</mat-icon
            >
          </mat-card-header>
          <mat-card-content style="display: flex">
            <mat-icon class="smsIcon">
              textsms <br />
              phone_in_talk</mat-icon
            >
            <p class="ellipsis">
              Send notification through telco partners.
              <br />
              <span *ngIf="number"
                ><b>Registered Number</b>: +{{ number }}</span
              >
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div *ngIf="smsEnabled">
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                [routerLink]="['./sms-and-call-registration']"
              >
                Update
              </button>
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                (click)="unregisterNumber()"
              >
                Remove
              </button>
            </div>
            <div *ngIf="!smsEnabled">
              <button
                mat-button
                class="proceed-button mb-2 mr-2"
                color="primary"
                [routerLink]="['./sms-and-call-registration']"
              >
                Register
                <mat-icon [inline]="true">arrow_forward</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
        <!-- Mobile Application Card -->
        <mat-card class="code-card">
          <mat-card-header>
            <mat-card-title class="purple-dark" style="font-weight: bold"
              >Mobile application</mat-card-title
            >
            <mat-icon class="checkIcon" *ngIf="appEnabled"
              >check_circle_outline</mat-icon
            >
          </mat-card-header>
          <mat-card-content style="display: flex">
            <mat-icon class="mobileIcon">mobile_friendly</mat-icon>
            <p class="ellipsis">
              Send alerts via push notification through the mobile application.
              <br />
              <span *ngIf="deviceModel"><b>Device</b>: {{ deviceModel }}</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <!-- <span class="comingSoon purple-dark">COMING SOON</span> -->
            <div *ngIf = "appEnabled">
              <button mat-button class="proceed-button mb-2 mr-2" color="primary" [routerLink]="['./mobile-app-registration']">
                Update
              </button>
              <button mat-button class="proceed-button mb-2 mr-2" color="primary"  (click)="unregisterDevice()">
                Remove
              </button>
            </div>
            <div *ngIf = "!appEnabled">
              <button mat-button class="proceed-button mb-2 mr-2" color="primary" [routerLink]="['./mobile-app-registration']">
                Register
                <mat-icon [inline]="true">arrow_forward</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-card>
  </div>

  <!-- Data Privacy page/content -->
  <div class="invitation" *ngIf="!accepted && !isLoading">
    <div class="welcome">
      <br />
      <p>Welcome!</p>
      <br />
      <p>
        You have been invited by {{ projectName }} to use the Real-time Alerts
        and Voice-Enabled Notification tool. To start receiving alerts, you must
        enable at least one of the available channels. By accepting the data
        privacy statement, you will be directed to the channel's selection.
      </p>
      <br />
    </div>
    <mat-card class="card">
      <br />
      <p style="margin-left: 20px; font-size: 20px">
        Please read the Data Privacy statement before you proceed with the
        registration.
      </p>
      <br />
      <mat-card class="privacyContent">
        <p>
          Please be aware that any personal data that you and others provide
          through this tool may be processed by Accenture for the purpose of
          sending alerts and notifications to your mobile phone in accordance
          with Accenture Data Privacy Statement.
          <br />
          <br />
          The protection of your personal data is very important to Accenture.
          Accenture is committed to keeping your personal data secure, and
          processing it in accordance with, applicable data protection laws and
          our internal policies, including Policies: 0090 - Data Privacy
          (English) (accenture.com).
          <br />
          <br />
          Your decision to provide your personal data to Accenture is voluntary.
          However, given that this tool requires personal data to function, you
          will not be able to use this tool if you do not provide your personal
          data.
          <br />
          <br />
          Before providing any personal data through this tool, Accenture
          invites you to carefully read GLOBAL DATA PRIVACY STATEMENT |
          Protecting Accenture, which includes important information on why and
          how Accenture is processing your personal data.
          <br />
          <br />
          Please also be aware that by using of this tool, you can still receive
          notifications even beyond your regular working hours and in such
          cases, there is no expectation from you to take any action outside of
          your working hours, holidays and rest day.
        </p>
      </mat-card>

      <mat-card-actions>
        <button
          mat-button
          class="proceed-button mb-2 mr-2"
          color="primary"
          (click)="acceptPrivacy('Decline')"
        >
          Decline
        </button>
        <button
          mat-button
          class="proceed-button mb-2 mr-2"
          color="primary"
          (click)="acceptPrivacy('Proceed')"
        >
          Proceed
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div>
    <mat-spinner
      style="margin: auto"
      mode="indeterminate"
      [diameter]="200"
      *ngIf="isLoading"
    ></mat-spinner>
  </div>
</div>
