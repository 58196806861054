import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeLogService } from 'src/app/service/change-log.service';
import { ProjectService } from 'src/app/service/project.service';
import { SnackBarService } from 'src/app/service/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success-reg-page',
  templateUrl: './success-reg-page.component.html',
  styleUrls: ['./success-reg-page.component.scss']
})
export class SuccessRegPageComponent implements OnInit {
  project$;
  contacts;
  groups;
  id: any;
  subscription: any;
  tagHeader;
  description;

  constructor(
    private _route: ActivatedRoute,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private changeLogger: ChangeLogService,
    private sBar: SnackBarService,

  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.id = params.id;
    });

    this.project$ = this.getProjectDetails(this.id);
    this.subscription = this.project$.subscribe(res => {
      this.contacts = res.contacts;
      this.groups = res.groups;
    });

    let URL = window.location.href.toString();

    if(URL.includes("mobile-app-registration")){
      this.tagHeader = "Mobile Application";
      this.description  = "Send alerts via push notification through the mobile application.";
    } else {
    this.tagHeader = 'SMS and Call';
    this.description  = "Send notification through telco partners.";
    }
  }
  getProjectDetails(id) {
    return this.projectService.getProjectById(id);
  }

  backToDevRegPage() {
    window.location.href = `${environment.frontEndURL}/${this.id}/device-registration`
  }
}


