import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button.component';

import { MaterialModule } from 'src/app/material/material.module';


@NgModule({
  declarations: [
    BackButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    BackButtonComponent
  ]
})
export class BackButtonModule { }
