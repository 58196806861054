import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reg-instruct',
  templateUrl: './reg-instruct.component.html',
  styleUrls: ['./reg-instruct.component.scss']
})
export class RegInstructComponent implements OnInit {
  regStatus;
  regInstruction;
  regCode;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RegInstructComponent>,
  ) { }
  
  ngOnInit(): void {
    
    if(this.data.regStatus.includes("Register Device")){
      this.regStatus = "Register Device";
      this.regInstruction = "To register please follow the instructions below:";
    } else {
      this.regStatus = "Update Device";                        
      this.regInstruction = "To update the device please follow the instructions below:";
    }
    this.regCode = this.data.registrationCode;
  }

  closeDialog(res?) {
    this.dialogRef.close(res);
  }
}
