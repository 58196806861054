<div class="container-fluid dark-bg pb-10">
  <div class="conainer-bg">
    <div class="row">
      <div class="col-12 justify-content-start">
        <app-raven-logo></app-raven-logo>
      </div>
    </div>
    <div class="row my-3 white">
      <div class="col-1 bckBtn">
        <app-back-button></app-back-button>
      </div>
    </div>
    <div class="margin">
      <ng-container>
        <div class="row mb-n2 ml-4">
          <div
            class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white"
          >
            {{ project.name }}
          </div>
          <div
            class="col-12 mat-subheading-1 justify-content-start d-flex white"
            style="margin-top: 1%"
          >
            {{ project.alignment }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row disclaimer">
      <div class="col">
        <p>
          <strong>Disclaimer:</strong> The data provided herewith is collected
          by the Point of Contact (POC) assigned to handle the emergency, and
          not by RAVEN or any other entity.​
        </p>
      </div>
    </div>
    <div class="row contacts-card">
      <div class="col">
        <mat-card>
          <mat-card-title class="card-title mb-3"> Contacts </mat-card-title>
          <div class="row">
            <div class="col-4 choose-file">
              <input
                #fileInput
                type="file"
                (change)="onFileSelected($event)"
                accept=".csv"
              />
            </div>
            <div class="col d-flex justify-content-start">
              <button
                mat-flat-button
                class="proceed-button mr-2"
                color="primary"
                (click)="importContacts()"
                [disabled]="!selectedFile || isFormEmpty || hasEmptyProperties"
              >
                <mat-icon>upload</mat-icon> Import
              </button>
              <button
                mat-button
                class="proceed-button"
                color="primary"
                (click)="downloadImportFile()"
              >
                <mat-icon>download</mat-icon> Download Import Template
              </button>
            </div>
            <div class="col d-flex justify-content-end">
              <button
                mat-flat-button
                class="proceed-button"
                color="primary"
                (click)="openContactForm('add', [])"
                [disabled]="selectedFile"
              >
                <mat-icon>add</mat-icon> Add Contact
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col mt-1">
              <span
                *ngIf="
                  (isFormEmpty && selectedFile) ||
                  (hasEmptyProperties && selectedFile)
                "
                class="error"
                >{{ errorMessage }}</span
              >
            </div>
            <app-people-acc-contacts-table
              [openContactForm]="openContactForm"
              [deleteRecord]="deleteRecord"
              [contactsData]="contactsData"
              [isLoading]="isLoadingContacts"
            ></app-people-acc-contacts-table>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row groups-card">
      <div class="col">
        <mat-card>
          <mat-card-title class="row d-flex">
            <div class="col mat-subheading-2 purple-dark">Groups</div>
            <div class="col d-flex justify-content-end">
              <button
                mat-flat-button
                class="proceed-button"
                color="primary"
                (click)="openGroupForm('add', '')"
              >
                <mat-icon>add</mat-icon> Add Group
              </button>
            </div>
          </mat-card-title>
          <div class="row groups-table">
            <app-people-acc-groups-table
              [openGroupForm]="openGroupForm"
              [deleteRecord]="deleteRecord"
              [groupsData]="groupsData"
              [isLoading]="isLoadingGroups"
            ></app-people-acc-groups-table>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  W
</div>
