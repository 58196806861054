<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <mat-toolbar>
      <div class="leftTopNav justify-content-start">
        <div class="row">
          <div class="col">
            <img src="assets/images/accenture_logo.png"/>
          </div>
          <div class="col">
            <span>|</span>
          </div>
          <div class="col mat-body-2 py-2 purple-light">
            RAVEN
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="mat-body-1 mr-4" *ngIf="isAdmin">
        <a [routerLink]="['admin']">
          Administrator
        </a>
      </div>
      <div class="rightTopNav justify-content-end" *ngIf="currentUser">
        <span class="mat-small">
          <mat-icon [inline]="true">person</mat-icon>
          {{currentUser.name}}
        </span>
      </div>
    </mat-toolbar>


  <router-outlet></router-outlet><!-- router outlet  -->



  </mat-sidenav-content>
</mat-sidenav-container>

