import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Usecase } from 'src/app/models/usecase.model';

@Injectable({
  providedIn: 'root'
})
export class UsecaseService {
  private updatedArray = new Subject<Usecase[]>();
  private usecases: Usecase[] = [];

  updateListener = () => this.updatedArray.asObservable()

  constructor(private http: HttpClient) { }

  readonly baseUrl = environment.apiURL + '/usecase';

  getUseCases(projectId) {
    const params = new HttpParams().set('id', projectId);
    this.http.get<Usecase[]>(this.baseUrl, {params})
      .subscribe(res => {
        this.usecases = res;
        this.updatedArray.next([...res]);
      });
  }

  getUsecaseById(id): Observable<Usecase>{
    let url = `${this.baseUrl}/${id}`;
    return this.http.get<Usecase>(url);
  }

  createUsecase(projectId, usecase): Observable<any> {
    let url = `${this.baseUrl}/create`;
    const params = new HttpParams().set('id', projectId);
    return this.http.post<{ msg: string, usecase: Usecase } | string>(url, usecase, {params})
  }

  deleteUsecase(projectId, usecase): Observable<any> {
    let url = `${this.baseUrl}/${usecase._id}/delete`;
    const params = new HttpParams().set('id', projectId);
    return this.http.delete<{ msg: string, id: string } | string>(url, {params});
  }

  updateUsecase(usecase): Observable<any>{
    let url = `${this.baseUrl}/${usecase._id}/update`;
    return this.http.put<{ msg: string, usecase: Usecase } | string>(url, usecase);
  }

  updateView(action: string, usecase?: Usecase, usecases?: Usecase[]) {
    if(action.toLowerCase() == 'add') {
      this.usecases.push(usecase)
    }
    else if(action.toLowerCase() == 'delete') {
      this.usecases = usecases;
    }
    else if(action.toLowerCase() == 'edit') {
      const updatedArr = [...this.usecases];
      const oldIndex = updatedArr.findIndex((u) => u._id === usecase._id);
      updatedArr[oldIndex] = usecase;
      this.usecases = updatedArr;
    }

    this.updatedArray.next([...this.usecases]);
  }

  generateXML(id) {
    let url = this.baseUrl + `/${id}/generateXML`;
    return this.http.get(url);
  }
}
