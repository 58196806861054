import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NavComponent } from './component/nav/nav.component';

import { UsecaseService } from './service/usecase.service';
import { ProjectService } from './service/project.service';
import { RavenAuthService } from './service/raven-auth.service';
import { ScenarioService } from './service/scenario.service';
import { TokenInterceptorService } from './service/token-interceptor.service';
import { PromptComponent } from './component/forms/prompt/prompt.component';
import { RegInstructComponent } from './component/forms/reg-instruct/reg-instruct.component';
import { UnregisterPromptComponent } from './component/forms/unregister-prompt/unregister-prompt.component';
import { DeviceRegistrationPageComponent } from './component/pages/device-registration-page/device-registration-page.component';
import { BackButtonModule } from './component/shared/back-button/back-button.module';
import { MobileAppRegPageComponent } from './component/pages/mobile-app-reg-page/mobile-app-reg-page.component';
import { SuccessRegPageComponent } from './component/pages/mobile-app-reg-page/success-reg-page/success-reg-page.component';
import { PrivacyDeclinePromptComponent } from './component/forms/prompt/privacy-decline-prompt/privacy-decline-prompt.component';
import { PrivacyDeclinePageComponent } from './component/pages/privacy-decline-page/privacy-decline-page.component';
import { SmsCallRegPageComponent } from './component/pages/sms-call-reg-page/sms-call-reg-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorMessageComponent } from './component/default/error-message/error-message.component';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { RavenLogoComponent } from './component/shared/raven-logo/raven-logo.component';
import { RavenLogoModule } from './component/shared/raven-logo/raven-logo.module';
import { PeopleAccountingHomePageComponent } from './component/pages/people-accounting-home-page/people-accounting-home-page.component';
import { PeopleAccountingPageComponent } from './component/pages/people-accounting-home-page/people-accounting-page/people-accounting-page.component';
import { EventPageComponent } from './component/pages/people-accounting-home-page/event-page/event-page.component';
import { ResponseLogTableComponent } from './component/tables/response-log-table/response-log-table.component';
import { ManageContactsComponent } from './component/pages/people-accounting-home-page/manage-contacts/manage-contacts.component';
import { PeopleAccContactsTableComponent } from './component/tables/people-acc-contacts-table/people-acc-contacts-table.component';
import { PeopleAccGroupsTableComponent } from './component/tables/people-acc-groups-table/people-acc-groups-table.component';
import { PeopleAccContactFormComponent } from './component/forms/people-acc-contact-form/people-acc-contact-form.component';
import { PeopleAccGroupFormComponent } from './component/forms/people-acc-group-form/people-acc-group-form.component';import { NotificationLogTableComponent } from './component/tables/notification-log-table/notification-log-table.component';
import { PeopleAccountingDeletePromptComponent } from './component/forms/prompt/people-accounting-delete-prompt/people-accounting-delete-prompt.component';
import { BackButtonPromptComponent } from './component/forms/prompt/back-button-prompt/back-button-prompt.component';
import { PeopleAccountingRegistrationComponent } from './component/pages/people-accounting-registration/people-accounting-registration.component';
import { PeopleAccSuccessRegPageComponent } from './component/pages/people-accounting-registration/people-acc-success-reg-page/people-acc-success-reg-page.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.CLIENT_ID,
      authority: environment.AUTHORITY,
      redirectUri: environment.REDIRECT_URI,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    PromptComponent,
    RegInstructComponent,
    UnregisterPromptComponent,
    DeviceRegistrationPageComponent,
    MobileAppRegPageComponent,
    SuccessRegPageComponent,
    PrivacyDeclinePromptComponent,
    PrivacyDeclinePageComponent,
    SmsCallRegPageComponent,
    ErrorMessageComponent,
    PeopleAccountingHomePageComponent,
    PeopleAccountingPageComponent,
    EventPageComponent,
    ManageContactsComponent,
    PeopleAccContactsTableComponent,
    PeopleAccGroupsTableComponent,
    PeopleAccContactFormComponent,
    PeopleAccGroupFormComponent,
    ResponseLogTableComponent,
    PeopleAccountingDeletePromptComponent,
    BackButtonPromptComponent,
    PeopleAccountingRegistrationComponent,
    PeopleAccSuccessRegPageComponent
    // NotificationLogTableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    HttpClientModule,
    LoadingBarModule,
    BackButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
	  MsalModule,
    RavenLogoModule,
  ],
  providers: [
    ProjectService,
    UsecaseService,
    ScenarioService,
    RavenAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
	MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
