<div class="container-fluid dark-bg pb-10">
  <div class="container-main">
    <div class="row">
      <div class="col-12 justify-content-start">
        <app-raven-logo></app-raven-logo>
      </div>
    </div>
    <div class="row my-3 white">
      <div class="col-1 bckBtn">
        <!-- <app-back-button></app-back-button> -->
        <button mat-button (click)="backButton()">
          <mat-icon> arrow_back </mat-icon>
        </button>
      </div>
    </div>
    <div class="margin">
      <ng-container>
        <div class="row mb-n2 ml-4">
          <div
            class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white"
          >
            {{ project.name }}
          </div>
          <div
            class="col-12 mat-subheading-1 justify-content-start d-flex white"
            style="margin-top: 1%"
          >
            {{ project.alignment }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row event-card" *ngIf="!isLoading">
      <div class="col">
        <mat-card>
          <mat-card-title class="event-title">
            <mat-icon>sms</mat-icon>
            NEW SMS ALERT
          </mat-card-title>
          <form
            [formGroup]="eventForm"
            (ngSubmit)="submitForm()"
            (keydown.enter)="$event.preventDefault()"
          >
            <div class="row event-form">
              <div class="col-6">
                <div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Drafts</mat-label>
                    <mat-select
                      (selectionChange)="
                        onChangeEvent('selectedDraft', $event.value)
                      "
                    >
                      <mat-option selected value="newEvent">New</mat-option>
                      <mat-option
                        *ngFor="let draft of draftsDropdown"
                        [value]="draft.eventID"
                        >{{ draft.eventName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Event Message Template</mat-label>
                    <mat-select
                      (selectionChange)="
                        onChangeEvent('selectedEventMsgTemp', $event.value)
                      "
                    >
                      <mat-option
                        *ngFor="let temp of eventMsgTempDropdown"
                        [value]="temp._id"
                        >{{ temp.templateName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Event Name</mat-label>
                    <input
                      matInput
                      #event
                      (change)="onChangeEvent('eventName', event.value)"
                      [value]="eventName"
                    />
                    <span
                      *ngIf="
                        isEventNameEmpty && eventName !== ''
                          ? false
                          : isEventNameEmpty
                      "
                      class="error"
                      >Event name is required
                    </span>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Expiration Date</mat-label>
                    <input
                      matInput
                      type="datetime-local"
                      name="meeting-time"
                      class="datetime-picker"
                      (change)="
                        onChangeEvent('expirationDateTime', $event.target.value)
                      "
                      [value]="expirationDateTime"
                      [min]="minDate"
                    />
                    <span
                      *ngIf="
                        isExpirationEmpty && expirationDateTime !== ''
                          ? false
                          : isExpirationEmpty
                      "
                      class="error"
                      >Expiration date and time is required
                    </span>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Contact Group</mat-label>
                    <mat-chip-list #groupList formControlName="groups">
                      <mat-chip
                        class="contact-chip"
                        *ngFor="let group of selectedGroups"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="removeGroup(group)"
                      >
                        {{ group.name }}
                        <button matChipRemove *ngIf="removable">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input
                        #groupInput
                        placeholder="Enter group..."
                        [matChipInputFor]="groupList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addGroup($event)"
                        [matAutocomplete]="groupsAutocomplete"
                        formControlName="groupInput"
                      />
                    </mat-chip-list>
                    <mat-autocomplete
                      #groupsAutocomplete="matAutocomplete"
                      (optionSelected)="selectGroup($event)"
                    >
                      <ng-container
                        *ngFor="let group of filteredGroups | async"
                      >
                        <mat-option class="contact-options" [value]="group">
                          {{ group.name }}
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                    <span
                      *ngIf="
                        isContactEmpty && selectedGroups.length > 0
                          ? false
                          : isContactEmpty
                      "
                      class="error"
                      >At least 1 contact group is required
                    </span>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-8 font-recipients color-recipients">
                    Total number of recipients:
                    <strong>{{ totalEidCount ?? 0 }}</strong>
                  </div>
                  <div
                    class="col-4 font-recipients align-right"
                    *ngIf="selectedGroups.length > 0"
                  >
                    <a (click)="onShowRecipients()" class="font-recipient"
                      >{{ viewOrHide }} Recipients</a
                    >
                  </div>
                  <div class="col mt-3 show-recipient" *ngIf="showRecipients">
                    <p class="color-recipients">Showing all recipients:</p>
                    <p class="font-recipient recipients-list">
                      <i>{{ totalEid }}</i>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div>
                  <mat-form-field class="w-100" appearance="fill">
                    <div class="mt-n3 d-flex justify-content-end">
                      <mat-slide-toggle
                        #reponse
                        color="primary"
                        (change)="
                          onChangeEvent('isResponseEnabled', reponse.checked)
                        "
                        [checked]="isResponseEnabled"
                      >
                        Enable two-way SMS
                      </mat-slide-toggle>
                    </div>
                    <mat-label>Event Message</mat-label>
                    <textarea
                      matInput
                      rows="10"
                      [class.default-height]="!isResponseEnabled"
                      [class.custom-height]="isResponseEnabled"
                      #eventMsg
                      (change)="onChangeEvent('eventMessage', eventMsg.value)"
                      [value]="eventMessage"
                      maxlength="1200"
                    ></textarea>
                    <p
                      *ngIf="isResponseEnabled"
                      class="mt-3 mb-0 event-msg-footer"
                    >
                      {{
                        !eventMsgFooter.length ? responseFooter : eventMsgFooter
                      }}
                    </p>
                    <mat-hint style="font-size: 12px">
                      {{ eventMsg.value.length }} of 1200 characters limit
                    </mat-hint>
                    <span
                      *ngIf="
                        isEventMessageEmpty && eventMessage !== ''
                          ? false
                          : isEventMessageEmpty
                      "
                      class="error"
                      ><br /><br />
                      Event message is required
                    </span>
                  </mat-form-field>
                </div>
                <div class="mt-4" *ngIf="isResponseEnabled">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Response Format</mat-label>
                    <input
                      matInput
                      #event
                      (change)="onChangeEvent('responseFormat', event.value)"
                      [value]="responseFormat"
                      (keyup)="onKeyUpResponse(event.value)"
                    />
                    <span
                      *ngIf="
                        isResponseEmpty && responseFormat !== ''
                          ? false
                          : isResponseEmpty
                      "
                      class="error"
                      >Response format is required
                    </span>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row mr-4">
              <div class="col">
                <div class="progress-bar" *ngIf="isActiveSendSMS">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div class="button-flex">
                  <div>
                    <button
                      mat-flat-button
                      class="btn-draft"
                      aria-label="Save as a draft"
                      (click)="saveAsDraft()"
                      [disabled]="isActiveSaveDraft"
                    >
                      <mat-icon>save</mat-icon>&nbsp;Save as Draft
                    </button>
                  </div>
                  <div class="ml-3">
                    <button
                      mat-flat-button
                      class="btn-send"
                      aria-label="Send SMS"
                      (click)="sendSMS()"
                      [disabled]="
                        isActiveSendSMS ||
                        eventName === '' ||
                        expirationDateTime === '' ||
                        selectedGroups.length < 1 ||
                        eventMessage === ''
                      "
                    >
                      <mat-icon>send</mat-icon>&nbsp;Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  <div>
    <mat-spinner
      class="loader-spinner"
      mode="indeterminate"
      [diameter]="200"
      *ngIf="isLoading"
    ></mat-spinner>
  </div>
</div>
