import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Project } from 'src/app/models/project.model';
import { RavenAuthService } from './raven-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private updatedArray = new Subject<Project[]>();
  private projects = [];

  updateListener = () => this.updatedArray.asObservable();

  constructor(private http: HttpClient,
    private rAuth: RavenAuthService) { }

  readonly baseUrl = environment.apiURL + '/projects'

  /******** projects ************/
  getProjects() {
    let url = this.baseUrl;
    let user = this.rAuth.getUser();
    

    const params = new HttpParams().set('eid', user.eid);
    this.http.get<Project[]>(url,{params}).subscribe(res => {
      this.projects = res;
      this.updatedArray.next([...res]);
    });
  }

  getProjectById(id): Observable<Project>{
    let url = `${this.baseUrl}/${id}`;
    return this.http.get<Project>(url);
  }

  createProject(project) {
    let url = `${this.baseUrl}/create`;
    return this.http.post<any>(url,project);
  }

  updateProject(id,project) {
    let url = `${this.baseUrl}/${id}/update`;
    return this.http.put<any>(url,project);
  }

  deleteProject(id) {
    let url = `${this.baseUrl}/${id}/delete`;
    return this.http.delete<any>(url);
  }

  updateView(action: string, project?) {
    if(action.toLowerCase() == 'add') {
      this.projects.push(project);
    }
    else if(action.toLowerCase() == 'delete') {
      this.projects = this.projects.filter(p => p._id != project._id)
    }
    else if(action.toLowerCase() == 'edit') {
      const updatedArr = [...this.projects];
      const oldIndex = updatedArr.findIndex((p) => p._id === project._id);
      updatedArr[oldIndex] = project;
      this.projects = updatedArr;
    }

    this.updatedArray.next([...this.projects]);
  }

  /******** contacts ************/
  createContact(projectId,contact): Observable<any> {
    let url = `${this.baseUrl}/${projectId}/contact/create`;
    return this.http.post<{msg: string, contacts: any[], contact: any} | any>(url,contact);
  }

  updateContact(projectId,contact,tagChanged?, emailChanged?): Observable<any> {
    let obj = {...contact, tagChanged, emailChanged};
    let url = `${this.baseUrl}/${projectId}/contact/update`;
    return this.http.put<{msg: string, contacts: any[], contact: any} | any>(url,obj);
  }

  deleteContact(projectId,contact): Observable<any> {
    let url = `${this.baseUrl}/${projectId}/contact/delete`;
    return this.http.put<{msg: string, contacts: any[], contact: any} | any>(url,contact);
  }

  /******** groups ************/
  createGroup(projectId,group): Observable<any> {
    let url = `${this.baseUrl}/${projectId}/group/create`;
    return this.http.post<{msg: string, groups: any[], group: any} | any>(url,group);
  }

  updateGroup(projectId,group): Observable<any> {
    let url = `${this.baseUrl}/${projectId}/group/update`;
    return this.http.put<{msg: string, groups: any[], group: any} | any>(url,group);
  }

  deleteGroup(projectId,group): Observable<any> {
    let url = `${this.baseUrl}/${projectId}/group/delete`;
    return this.http.put<{msg: string, groups: any[], group: any} | any>(url,group);
  }
}
