<div class="dark-bg">
  <br>
  <br>
  <div class="header white">
      <p>RAVEN REGISTRATION</p>
  </div>

  <div class="white bckBtn">
      <app-back-button></app-back-button>
  </div>

  <div class="margin">
    <div class="col-12 mb-n1 mat-display-1 justify-content-start d-flex white" *ngIf="(project$ | async) as project">
        {{project.name}}
    </div>
    <div class="col-12 mat-subheading-1 justify-content-start d-flex white" style="margin-top: 1%;" *ngIf="(project$ | async) as project">
        {{project.alignment}}
    </div>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title class="mat-subheading-2 cardHeader purple-dark" style="font-size: 35px;">{{tagHeader}}</mat-card-title>
        <mat-card-subtitle class="subCardHeader">{{description}}</mat-card-subtitle>
      </mat-card-header>

      <mat-divider style= "margin-top: 2%;" insert></mat-divider>

      <div class = "row" style="margin-top: 5.5%">
        <mat-card class="code-card">
          <mat-card-content style="text-align: left; font-size: 15px; margin-top: 3px;">
            <p style="font-size: x-large;">INSTRUCTION:</p>
            <p>1. Download and install <b>InTune Company Portal</b> in Google Play Store and Apple App Store.</p>
            <p>2. Download and install <b>RAVEN Mobile</b> in InTune Company Portal app. </p>
            <p>3. Open RAVEN Mobile and select <b>Connect Device</b>.</p>
          </mat-card-content>
        </mat-card>

        <mat-card class="code-card">
          <form [formGroup] = 'sixDigitCodeFormGroup'>
            <mat-card-content class="cardContent">
              
              <p style="font-size:x-large;">
                ENTER THE 6 DIGIT CODE FROM YOUR DEVICE
              </p>
              <mat-form-field appearance="outline" [style.width.%]="30">
                <mat-label>SIX DIGIT CODE</mat-label>
                <input matInput
                oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                maxlength="6"
                type = "text"
                [formControlName] = "'code'">
                <mat-error style="margin-top: 15px" *ngIf="this.sixDigitCodeFormGroup.controls['code'].invalid">
                  Not a <strong>valid code</strong>
                </mat-error>
              </mat-form-field>
              
            </mat-card-content>
            <mat-card-actions>
              <button mat-button class="proceed-button mb-2 mr-2" 
                color="primary"
                type="button"
                (click)="onSubmit()" 
                [disabled]="this.sixDigitCodeFormGroup.controls['code'].invalid" >
                Submit
              </button>
            </mat-card-actions>
          </form>
        </mat-card>

        <div class="cnclBtn">
          <button mat-flat-button 
            type = "button"
            color="primary"
            (click)="onCancel()">
            Cancel
          </button>
        </div>
      </div>
  </mat-card>
  </div>
</div>
