<div class="link">
  <div class="col mt-4 d-flex" (click)="goHome()">
    <img class="mx-auto my-1" src="assets/images/raven_logo.png"/>
  </div>
  <div class="col-lg-12 justify-content-center d-flex white ls-1" (click)="goHome()">
    RAVEN
  </div>
  <div class="col d-flex">
    <svg class="mx-auto" width="29px" height="4px">
      <rect width="29px" height="4px" style="fill:#9D00FF"></rect>
    </svg>
  </div>
</div>

