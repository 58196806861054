<div class="dark-bg">
    <br>
    <br>
    <br>
    <div class="header" *ngIf="destination !== 'peopleAccounting'">
        RAVEN REGISTRATION
    </div>
    <div class="header header-people-acc" *ngIf="destination === 'peopleAccounting'">
        PEOPLE ACCOUNTING REGISTRATION
    </div>
    <br>
    <br>
    <br>
    <div>
        <mat-card class="card">
            <mat-card-content class="card-content">
                By declining Data Privacy Statement, you are not applicable to register your Mobile Device and/or Mobile Number from the time being. You may now close this window.
            </mat-card-content>
        </mat-card>
    </div>
</div>